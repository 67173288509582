import Button from 'components/Button';
import useOrder from 'hooks/useOrder';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { Redirect, useHistory } from 'react-router-dom';
import addItemToOrder from '../../cartProcessor/addItemToOrder';
import updateItemToOrder from '../../cartProcessor/updateItemToOrder';
import useSpecialsCategoryIndex from '../../customHooks/useSpecialsCategoryIndex';
import addHalfHalfToOrder from '../../cartProcessor/addHalfHalfToOrder';
import { activeSpecialsSelector } from '../../pages/Dashboard/selectors';
import {
  checkItemReadyForOrder,
  getItemBaseSubModifierId,
  incrementItemQuantitySingleSize,
  prepareItemForOrder,
} from '../../itemProcessor';
import {
  resetMenuItem,
  updateCurrentFourQuarterItem,
  updateCurrentHalfHalfItem,
  updateCurrentOrder,
  updateCurrentSpecialsCategoryItem,
  updateSpecialsCurrentFourQuarterItem,
  resetCurrentHalfHalfData,
  updateSpecialsCurrentHalfHalfItem,
  setIsSingleHalfFlow,
  setHalfHalfMenuItems,
} from '../../pages/Dashboard/action';
import {
  orderSetupSelector,
  productSetupSelector,
  publicHolidaysSelector,
  specialsSelector,
  storeConfigSelector,
  suburbsSelector,
} from '../../pages/Dashboard/selectors';
import { ScrollableBoxUI } from '../../UI/components';
import { ItemDetails } from './ItemDetails';
import { ItemQuantity } from './ItemQuantity';
import { Sizes } from './Sizes';
import { SubModifiers } from './SubModifiers';
import generateHalfHalfMenuItems from 'util/generateHalfHalfMenuItems';
import updateHalfHalfToOrder from '../../cartProcessor/updateHalfHalfToOrder';

export const ItemView = (props) => {
  const [{ selectedCategoryId }] = useOrder();
  const currentOrder = useSelector((s) => s.dashboardReducer.currentOrder);
  const activeSpecials = useSelector(activeSpecialsSelector);
  const isSingleHalfFlow = useSelector(
    (s) => s.dashboardReducer.isSingleHalfFlow
  );
  const specials = useSelector(specialsSelector);
  const orderSetup = useSelector(orderSetupSelector);
  const publicHolidays = useSelector(publicHolidaysSelector);
  const suburbs = useSelector(suburbsSelector);
  const productSetup = useSelector(productSetupSelector);
  const storeConfig = useSelector(storeConfigSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { itemId, modifierId } = useParams();

  const currentSpecialsCategoryIndex = useSpecialsCategoryIndex();

  const isNotSpecials =
    currentSpecialsCategoryIndex === -1 || selectedCategoryId !== 'specials';
  const currentSpecialsItemId = useSelector(
    (state) => state.dashboardReducer.currentSpecialsItem?._id
  );
  const currentSpecialItemSelectedCategories = useSelector(
    (state) => state.dashboardReducer.currentSpecialsItem?.selectedCategories
  );
  const isCurrentSpecialHalfHalf = currentSpecialItemSelectedCategories
    ? currentSpecialItemSelectedCategories[currentSpecialsCategoryIndex]
        ?.currentHalfHalfData
    : false;

  const isCurrentSpecialFourQuater = currentSpecialItemSelectedCategories
    ? currentSpecialItemSelectedCategories[currentSpecialsCategoryIndex]
        ?.currentFourQuarterData
    : false;

  const halfIndexSpecialItem = useMemo(
    () =>
      currentSpecialItemSelectedCategories
        ? currentSpecialItemSelectedCategories[
            currentSpecialsCategoryIndex
          ]?.currentHalfHalfData?.halfHalfs?.find((h) => h.isInEditMode)
            ?.halfIndex
        : {},
    [currentSpecialsCategoryIndex, currentSpecialItemSelectedCategories]
  );

  const currentHalfHalfData = useSelector(
    (state) => state.dashboardReducer.currentHalfHalfData
  );
  const halfHalfCategoryId = currentHalfHalfData?.activeCategoryId;
  const halfIndex = useMemo(
    () =>
      currentHalfHalfData?.halfHalfs?.find((h) => h.isInEditMode)?.halfIndex,
    [currentHalfHalfData?.halfHalfs]
  );
  let currentItem = useSelector((s) => s.dashboardReducer.currentMenuItem);
  const { quarterIndex } = currentItem;
  currentItem = {
    ...currentItem,
    iteration: currentOrder.iteration,
  };

  const currentFourQuarterData = useSelector(
    (state) => state.dashboardReducer.currentFourQuarterData
  );
  const fourQuarterCategoryId = currentFourQuarterData?.activeCategoryId;
  const fourQuarterIndex = useMemo(
    () =>
      currentFourQuarterData?.fourQuarters?.find((h) => h.isInEditMode)
        ?.fourQuarterIndex,
    [currentFourQuarterData?.fourQuarters]
  );

  let itemDetailsRef = useRef();
  const [customHeight, setCustomHeight] = useState(175);
  const [readyForOrderObj, updateItemStatusForOrder] = useState(
    checkItemReadyForOrder(currentItem)
  );
  const [itemBaseSubModifierId, updateItemBaseSubModifierId] = useState(
    getItemBaseSubModifierId(currentItem)
  );
  useEffect(() => {
    if (isSingleHalfFlow && currentItem?.pizzaStatus === 'half') {
      dispatch(
        updateCurrentHalfHalfItem({
          ...currentItem,
          selectedSizes: currentItem.selectedSizes.map((size) => {
            if (size.quantity > 0) {
              return {
                ...size,
                quantity: 0.5,
              };
            }
            return {
              ...size,
            };
          }),
          selectedModifiers: currentItem.selectedModifiers?.map((modifier) => {
            const subModifiers = modifier.subModifiers?.map((subModifier) => {
              if (subModifier.quantity > 0) {
                return {
                  ...subModifier,
                  quantity: 0.5,
                };
              }
              return subModifier;
            });

            return {
              ...modifier,
              subModifiers,
            };
          }),
          halfIndex: 1,
          isHalf: true,
          isSecondHalf: false,
          quantity: 0.5,
        })
      );
      dispatch(
        updateCurrentHalfHalfItem({
          ...currentItem,
          selectedSizes: currentItem.selectedSizes.map((size) => {
            if (size.quantity > 0) {
              return {
                ...size,
                quantity: 0.5,
              };
            }
            return {
              ...size,
            };
          }),
          selectedModifiers: currentItem.selectedModifiers?.map((modifier) => {
            const subModifiers = modifier.subModifiers?.map((subModifier) => {
              if (subModifier.quantity > 0) {
                return {
                  ...subModifier,
                  quantity: 0.5,
                };
              }
              return subModifier;
            });

            return {
              ...modifier,
              subModifiers,
            };
          }),
          selectedExtraIngredients: [],
          selectedIngredients: currentItem.selectedIngredients?.map(
            (ingredient) => {
              return { ...ingredient, isRemoved: false };
            }
          ),
          halfIndex: 2,
          isHalf: true,
          isSecondHalf: true,
          quantity: 0.5,
        })
      );
    }
  }, [JSON.stringify(currentItem.selectedSizes)]);

  useEffect(() => {
    if (currentItem) {
      const newStatus = checkItemReadyForOrder(currentItem);
      const newBaseSubModifierId = getItemBaseSubModifierId(currentItem);
      if (
        newStatus.isReady !== readyForOrderObj.isReady ||
        newStatus.itemQuantity !== readyForOrderObj.itemQuantity
      ) {
        updateItemStatusForOrder(newStatus);
      }
      if (newBaseSubModifierId !== itemBaseSubModifierId) {
        updateItemBaseSubModifierId(getItemBaseSubModifierId(currentItem));
      }
    }
  }, [
    currentItem,
    itemBaseSubModifierId,
    readyForOrderObj.isReady,
    readyForOrderObj.itemQuantity,
  ]);

  // useEffect(() => {
  // if (itemDetailsRef !== undefined) {
  // if (itemDetailsRef.current && itemDetailsRef.current.clientHeight) {
  // setCustomHeight(itemDetailsRef.current.clientHeight);
  // }
  // }
  // }, [itemDetailsRef]);

  if (!itemId) {
    return <Redirect to={props.parentPage} />;
  }
  const handleAddOneSideHalfOrder = () => {
    let currentMenuItems =
      currentHalfHalfData.halfHalfs?.map((cat) => {
        if (cat.halfIndex === 1) {
          return { ...cat.currentMenuItem };
        }
        return cat.currentMenuItem;
      }) || [];

    if (productSetup.chargeExpensiveHalf) {
      // let newPrice = findPriceOfMostExpensiveItem(currentMenuItems);
      // currentMenuItems = updateItemsWithNewPrice(currentMenuItems, newPrice);
      currentMenuItems = generateHalfHalfMenuItems(currentMenuItems);
    }
    const { isInModifyMode = false } = currentHalfHalfData;

    if (isInModifyMode) {
      dispatch(
        updateCurrentOrder(
          updateHalfHalfToOrder(
            currentOrder,
            currentMenuItems,
            activeSpecials,
            orderSetup,
            productSetup,
            suburbs,
            publicHolidays,
            storeConfig
          )
        )
      );
    } else {
      dispatch(
        updateCurrentOrder(
          addHalfHalfToOrder(
            currentOrder,
            currentHalfHalfData,
            currentMenuItems,
            activeSpecials,
            orderSetup,
            productSetup,
            suburbs,
            publicHolidays,
            storeConfig
          )
        )
      );
    }
    dispatch(resetCurrentHalfHalfData());
    dispatch(resetMenuItem());
    history.push(`${props.parentPage}/order`);
  };

  const handleAddOrder = () => {
    let newOrder = !currentItem.isCurrentMenuItemInEditMode
      ? addItemToOrder(
          currentOrder,
          prepareItemForOrder(currentItem, currentOrder),
          specials,
          orderSetup,
          productSetup,
          suburbs,
          publicHolidays,
          storeConfig
        )
      : updateItemToOrder(
          currentOrder,
          prepareItemForOrder(
            {
              ...currentItem,
              isCurrentMenuItemInEditMode: undefined,
            },
            currentOrder
          ),
          specials,
          orderSetup,
          productSetup,
          suburbs,
          publicHolidays,
          storeConfig
        );

    dispatch(updateCurrentOrder(newOrder));
    dispatch(resetMenuItem());
    history.push(`${props.parentPage}/order`, location.state);
  };

  const handleAddHalfHalfSpecials = () => {
    const categoryIndex = !isNotSpecials
      ? currentSpecialsCategoryIndex
      : undefined;
    dispatch(
      updateSpecialsCurrentHalfHalfItem(parseInt(categoryIndex), {
        ...currentItem,
        halfIndex: parseInt(halfIndexSpecialItem),
        isHalf: true,
        isSecondHalf: parseInt(halfIndexSpecialItem) === 2,
      })
    );
    history.push(
      `${props.parentPage}/specials/${currentSpecialsItemId}/${categoryIndex}/half-half`,
      location.state
    );
  };

  const handleAddFourQuartersSpecials = () => {
    const categoryIndex = !isNotSpecials
      ? currentSpecialsCategoryIndex
      : undefined;

    dispatch(
      updateSpecialsCurrentFourQuarterItem(parseInt(categoryIndex), {
        ...incrementItemQuantitySingleSize(currentItem, 0.25),
        fourQuarterIndex: parseInt(quarterIndex),
        isQuarter: true,
        isSecondQuarter: parseInt(quarterIndex) === 2,
        isThirdQuarter: parseInt(quarterIndex) === 3,
        isFourthQuarter: parseInt(quarterIndex) === 4,
      })
    );
    history.push(
      `${props.parentPage}/specials/${currentSpecialsItemId}/${categoryIndex}/four-quarter`,
      location.state
    );
  };

  const handleAddSpecials = () => {
    const categoryIndex = !isNotSpecials
      ? currentSpecialsCategoryIndex
      : undefined;
    dispatch(
      updateCurrentSpecialsCategoryItem(categoryIndex, {
        ...currentItem,
        isSpecialItem: true,
      })
    );
    history.push(
      `${props.parentPage}/specials/${currentSpecialsItemId}`,
      location.state
    );
  };

  const handleAddHalfHalf = () => {
    dispatch(
      updateCurrentHalfHalfItem({
        ...currentItem,
        halfIndex: parseInt(halfIndex),
        isHalf: true,
        isQuarter: false,
        isSecondHalf: parseInt(halfIndex) === 2,
      })
    );
    history.push(
      `${props.parentPage}/category/${halfHalfCategoryId}/half-half`,
      location.state
    );
  };

  const handleAddFourQuarter = () => {
    dispatch(
      updateCurrentFourQuarterItem({
        ...currentItem,
        fourQuarterIndex: parseInt(fourQuarterIndex),
        isQuarter: true,
        isHalf: false,
        isSecondQuarter: parseInt(fourQuarterIndex) === 2,
        isThirdQuarter: parseInt(fourQuarterIndex) === 3,
        isFourthQuarter: parseInt(fourQuarterIndex) === 4,
      })
    );
    history.push(
      `${props.parentPage}/category/${fourQuarterCategoryId}/four-quarter`,
      location.state
    );
  };

  const getTitle = () => {
    if (!isNotSpecials && isCurrentSpecialHalfHalf) {
      return 'Add to Half/Half';
    }

    if (!isNotSpecials && isCurrentSpecialFourQuater) {
      return 'Add to Four Quarters';
    }

    if (!isNotSpecials) {
      return 'Add to Specials';
    }

    if (!!halfHalfCategoryId && !isSingleHalfFlow) {
      return 'Add to Half/Half';
    }

    if (!!fourQuarterCategoryId) {
      return 'Add to Four Quarters';
    }

    return 'Add to Order';
  };

  const handleClick = () => {
    if (!isNotSpecials && isCurrentSpecialHalfHalf) {
      handleAddHalfHalfSpecials();
    } else if (!isNotSpecials && isCurrentSpecialFourQuater) {
      handleAddFourQuartersSpecials();
    } else if (!isNotSpecials) {
      handleAddSpecials();
    } else if (!!halfHalfCategoryId && !isSingleHalfFlow) {
      handleAddHalfHalf();
    } else if (!!fourQuarterCategoryId) {
      handleAddFourQuarter();
    } else if (!!halfHalfCategoryId && isSingleHalfFlow) {
      handleAddOneSideHalfOrder();
      dispatch(setIsSingleHalfFlow(false));
      dispatch(setHalfHalfMenuItems([]));
    } else {
      handleAddOrder();
      dispatch(setIsSingleHalfFlow(false));
      dispatch(setHalfHalfMenuItems([]));
    }
  };
  return (
    <div className="main-area modifiers-style" id="main-area">
      <div className="main-area-inner">
        <ItemDetails ref={itemDetailsRef} currentItem={currentItem} />
        <div
          className="main-area-body-wrap"
          style={{
            height: 'calc(100% - ' + customHeight + 'px)',
          }}
        >
          <ScrollableBoxUI>
            <div className="main-area-body">
              {currentItem.itemType === 0 ? (
                <Sizes
                  currentItem={currentItem}
                  basePath={props.parentPage}
                  autoShowCustomizeOrderScreenForKiosk={
                    orderSetup?.autoShowCustomizeOrderScreenForKiosk
                  }
                />
              ) : (
                <SubModifiers
                  navigateTo={`${props.parentPage}/menuitem/${currentItem._id}/modifiers`}
                  modifierId={modifierId}
                  currentItem={currentItem}
                  itemBaseSubModifierId={itemBaseSubModifierId}
                  basePath={props.parentPage}
                  autoShowCustomizeOrderScreenForKiosk={
                    orderSetup?.autoShowCustomizeOrderScreenForKiosk
                  }
                />
              )}
            </div>
          </ScrollableBoxUI>
          {readyForOrderObj.isReady && (
            <div className="main-bottom-btn-box">
              {
                //hide if specials is selected
                isNotSpecials &&
                  !halfHalfCategoryId &&
                  !fourQuarterCategoryId && (
                    <ItemQuantity
                      currentItem={currentItem}
                      itemQuantity={readyForOrderObj.itemQuantity}
                    />
                  )
              }

              <Button
                title={getTitle()}
                onClick={handleClick}
                className="btn big hover-highlight"
                enableThemeColor
                style={{
                  borderRadius: 8,
                }}
              >
                {getTitle()}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
