import React, { useRef, useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import GeneralModal from '../GeneralModal/index-new';
import { PosIconLeftChevronSecond } from '../../assets/icons/PosIconLeftChevronSecond';
import { PosIconDot } from '../../assets/icons/PosIconDot';
import RefundContext from '../../context/RefundContext';

const capitalize = (s) => `${s.substr(0, 1).toUpperCase()}${s.substr(1)}`;

const RefundAmountModal = ({ showModal, onModalClose, refundType }) => {
  const [total, setTotal] = useState('');
  const [showBtn, setShowBtn] = useState(false);
  const mainRef = useRef();
  const [state, setState] = useContext(RefundContext);

  useEffect(() => {
    if (Number(total)) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  }, [total]);

  useEffect(() => {
    setTotal('');
    setShowBtn(false);
  }, []);

  const keyList = useRef([
    { id: 1, name: '1', val: '1' },
    { id: 2, name: '2', val: '2' },
    { id: 3, name: '3', val: '3' },
    { id: 4, name: '4', val: '4' },
    { id: 5, name: '5', val: '5' },
    { id: 6, name: '6', val: '6' },
    { id: 7, name: '7', val: '7' },
    { id: 8, name: '8', val: '8' },
    { id: 9, name: '9', val: '9' },
    {
      id: 10,
      name: <PosIconDot mainColor="515151" />,
      val: '.',
    },
    { id: 11, name: '0', val: '0' },
    {
      id: 13,
      name: <PosIconLeftChevronSecond mainColor="#515151" />,
      val: 'clear',
    },
  ]);

  const handleKeyDown = (e) => {
    if (e !== undefined && e.keyCode !== undefined) {
      let keyVal = '';
      const keyDotList = [190, 110];
      const keyDelList = [8, 46];
      const keyNumList = [
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105,
      ];

      if (keyDelList.includes(Number(e.keyCode || e.which))) {
        keyVal = 'clear';
      } else if (keyDotList.includes(Number(e.keyCode || e.which))) {
        keyVal = '.';
      } else if (keyNumList.includes(Number(e.keyCode || e.which))) {
        keyVal = e.key;
      }

      if (keyVal) {
        updateAmount(keyVal);
      }
    }
  };

  const updateAmount = (value) => {
    const currTotal = total.split('');
    if (value !== 'clear') {
      currTotal.push(value);
    } else {
      currTotal.pop();
    }
    setTotal(currTotal.join(''));
  };

  return (
    <GeneralModal
      modalStatus={showModal && state.step === 'amount'}
      toggleModal={() => setState({ step: 'affected-items' })}
      position="center"
      effect="move"
      overlay=""
      boxWidth="437px"
      boxHeight="688px"
      className="general-modal refund-manual-amount"
      closeBtn="outside"
      isResponsiveHeight
    >
      {showModal && state.step === 'amount' && (
        <div className="general-modal__inner">
          <div className="general-modal__header general-modal__header--top-offset">
            <div className="general-modal__title-box">
              <h3 className="general-modal__title general-modal__title--size-normal">
                Enter {capitalize(refundType)} Amount
              </h3>
            </div>
          </div>
          <div className="general-modal__body">
            <div className="general-modal__total-box">
              <div className="general-modal__total">
                {total ? `$${total}` : '$0.00'}
              </div>
            </div>
          </div>
          <div className="general-modal__footer general-modal__footer">
            <div
              ref={mainRef}
              onKeyDown={handleKeyDown}
              tabIndex="0"
              className="general-modal__keypad"
            >
              {keyList.current.map((item) => {
                const itemCls = clsx([
                  'general-modal__key',
                  [`key-${item.val.toLowerCase()}`],
                ]);
                return (
                  <button
                    key={item.id}
                    type="button"
                    title={item.val}
                    className={itemCls}
                    onClick={(e) => updateAmount(item.val)}
                  >
                    {item.name}
                  </button>
                );
              })}
            </div>
            <button
              onClick={() => {
                setState({
                  manualAmount: true,
                  refundAmount: parseFloat(total),
                  step: 'details',
                });
              }}
              className="button button--rounded-none button--align-center button--size-biggest button--fullwidth button--theme-green"
              title="Next"
              type="button"
              disabled={!showBtn}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </GeneralModal>
  );
};

export default RefundAmountModal;
