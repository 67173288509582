import axios from 'axios';
import StaffPinModal from 'components/StaffPinModal';
import TransferOrderModal from 'components/TransferOrderModal';
import { orderType } from 'configuration/constants';
import { useModal } from 'hooks';
import AccessDeniedModal from 'modals/AccessDeniedModal';
import ModalViewOrdersTime from 'modals/ModalViewOrdersTime';
import * as actions from 'pages/Dashboard/action';
import {
  authenticateStaff,
  loadOrderPayments,
  setActiveStaffMember,
  setGoToRouteAfterAuth,
  updateCurrentOrder,
} from 'pages/Dashboard/action';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import CashAmountSelectedModal from '../../components/CashAmountSelectedModal';
import PaymentViewModal from '../../components/PaymentViewModal';
import SplitPaymentsRoot from '../../components/SplitPayments';
import { nextOrderConfig } from '../../configuration/nextOrderConfig';
import { PrinterProvider } from '../../context/PrinterContext';
import { RefundProvider } from '../../context/RefundContext';
import {
  RefundAffectedItemsModal,
  RefundAmountModal,
  RefundDetailsModal,
  RefundErrorModal,
  RefundIssuedModal,
  RefundPhoneModal,
  RefundReasonModal,
  StepModal,
} from '../../modals';
import UberDriverModal from '../../components/UberDriverModal/UberDriverModal';
import { ActionsModal } from '../../modals/ActionsModal';
import { PrintOrderModal } from '../../modals/PrintOrderModal';
import { setShowSplitPaymentModal } from './actions';
import CancelOrderAlertModal from './components/CancelOrderAlertModal';
import { setSelectedOrder, setState } from './newOrdersReducer';
import {
  actionsModalSelector,
  paymentViewModalSelector,
  refundOrderModalSelector,
  showSplitPaymentModalSelector,
  transferOrderModalSelector,
  requestUberDriverSelector,
} from './selectors';
import { ModalPortal } from 'components';
import SplitBillDiscountModal from 'modals/SplitBillDiscountModal';

const ModalsWrapper = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const actionsModal = useSelector(actionsModalSelector);
  const storeConfig = useSelector(
    (state) => state.dashboardReducer.storeConfig
  );
  const requestUberDriver = useSelector(requestUberDriverSelector);
  const paymentViewModal = useSelector(paymentViewModalSelector);
  const showSplitPaymentModal = useSelector(showSplitPaymentModalSelector);
  const refundOrderModal = useSelector(refundOrderModalSelector);
  const transferOrderModal = useSelector(transferOrderModalSelector);
  const pinModal = useSelector((state) => state.ordersReducer.pinModal);
  const cashModalTitle = useSelector((state) => state.dashboardReducer.title);
  const [errorMessage, setErrorMessage] = useState('');
  const [wiggleEffect, setWiggleEffect] = useState(false);
  const [accessDenied, setShowAccessDenied] = useState(false);

  const history = useHistory();

  const [showCashAmountModal, setShowCashAmountModal] = useState(false);
  const [cashAmountChange, setCashAmountChange] = useState(0);
  const [cashPaid, setCashPaid] = useState(0);

  const { orders, selectedOrderId } = useSelector(
    (state) => state.newOrdersReducer
  );

  const selectedOrder = useMemo(
    () => orders.allOrdersMap[selectedOrderId] || null,
    [selectedOrderId, orders.allOrdersMap]
  );

  const handleCashAmountChange = (cashAmountChangeNew) => {
    setCashAmountChange(cashAmountChangeNew);
  };

  const handleCashPaid = (cashPaidNew) => {
    setCashPaid(cashPaidNew);
  };

  const handlePaymentModalClose = useCallback(() => {
    dispatch({
      type: 'togglePaymentViewModal',
      payload: false,
    });
  }, [dispatch]);

  const handleTransferOrderModalClose = useCallback(() => {
    dispatch({
      type: 'transferOrderModal',
      payload: false,
    });
  }, [dispatch]);

  const handleToggleModal = (e, status) => {
    dispatch({
      type: 'toggleActionsModal',
      payload: status,
    });
  };

  const handleCashAmountSelectedModal = (orderId = '') => {
    handlePaymentModalClose();
    dispatch(setState({ selectedOrderId: orderId }));
    setShowCashAmountModal(true);
  };

  const handleCashAmountModalClose = () => {
    setShowCashAmountModal(false);
  };

  useEffect(() => {
    return () => {
      handlePaymentModalClose();
    };
  }, [handlePaymentModalClose]);

  const [cancelOrderState, setCancelOrderState] = useState({
    state: 'idle',
    showModal: false,
  });

  const handleCancelOrderClick = () => {
    setCancelOrderState({ ...cancelOrderState, showModal: true });
  };

  const handleAbortCancelClick = () => {
    if (cancelOrderState.state !== 'loading') {
      setCancelOrderState({ ...cancelOrderState, showModal: false });
    }
  };

  const handleConfirmCancelOrder = async () => {
    if (cancelOrderState.state !== 'loading') {
      setCancelOrderState({ ...cancelOrderState, state: 'loading' });
      await axios.post(`${nextOrderConfig?.cloudFunctionUrl}/cancelOrder`, {
        orderId: selectedOrder._id,
      });
      setCancelOrderState({ showModal: false, state: 'resolved' });
      dispatch(setSelectedOrder(null));
    }
  };

  const handleSplitPaymentClick = () => {
    handlePaymentModalClose();
    dispatch(loadOrderPayments(selectedOrder._id));
    dispatch(setShowSplitPaymentModal(true));
  };

  const handleSplitPaymentModalClose = () => {
    dispatch(setShowSplitPaymentModal(false));
  };

  const handleCloseRequestUberDriverModal = () => {
    dispatch(actions.toggleBlurEffect(false));
    dispatch({
      type: 'requestUberDriver',
      payload: false,
    });
  };

  const handlePinComplete = useCallback(
    async (data) => {
      // make api call to authenticate the staff
      const selectedOrderTypeRoute =
        orderType.find((o) => o.id === selectedOrder?.orderType)?.route || '';
      dispatch(authenticateStaff(true));
      dispatch(setActiveStaffMember(data.staffMember));
      dispatch(
        updateCurrentOrder({
          ...selectedOrder,
          iteration: selectedOrder?.iteration + 1 || 1,
          prevIteration: selectedOrder?.iteration || 0,
          isEditOrder: true,
          menuItems: selectedOrder.menuItems.map((menuItem) =>
            typeof menuItem.iteration === 'undefined'
              ? { ...menuItem, iteration: selectedOrder.iteration || 0 }
              : menuItem
          ),
        })
      );
      dispatch(loadOrderPayments(selectedOrder._id));
      dispatch({ type: 'togglePinModal', payload: { showModal: false } });
      history.push(`${selectedOrderTypeRoute}`, {
        referrer: location.pathname,
      });
    },
    [dispatch, selectedOrder, history, location.pathname]
  );

  const isAuthDisabledForPOS = useSelector(
    (state) => state.dashboardReducer.orderSetup.isAuthDisabledForPOS
  );

  const [_, { openModal: openPinModal }] = useModal('staffPinModal');

  const handlePinModalOpen = (order) => {
    const selectedOrderTypeRoute =
      orderType.find((o) => o.id === order?.orderType)?.route || '';
    if (isAuthDisabledForPOS) {
      dispatch(actions.authenticateStaff(true));
      dispatch(
        actions.updateCurrentOrder({
          ...order,
          iteration: order?.iteration + 1 || 1,
          prevIteration: order?.iteration || 0,
          editOrderHideOrderButton: true,
          isEditOrder: true,
          menuItems: order.menuItems.map((menuItem) =>
            typeof menuItem.iteration === 'undefined'
              ? { ...menuItem, iteration: order.iteration || 0 }
              : menuItem
          ),
        })
      );
      dispatch(actions.loadOrderPayments(order._id));
      history.push(`${selectedOrderTypeRoute}`, location.state);
    } else {
      dispatch(setGoToRouteAfterAuth(selectedOrderTypeRoute));
      openPinModal();
      dispatch({ type: 'togglePinModal', payload: { showModal: true } });
    }
  };

  return (
    <>
      {actionsModal && selectedOrder && (
        <ActionsModal
          modalStatus={actionsModal}
          toggleModal={handleToggleModal}
          currentGuest={selectedOrder}
          selectedOrder={selectedOrder}
          handlePinModalOpen={handlePinModalOpen}
          handleCancelOrderClick={handleCancelOrderClick}
        />
      )}
      {requestUberDriver && (
        <ModalPortal>
          <UberDriverModal
            toggleModal={handleCloseRequestUberDriverModal}
            selectedOrder={selectedOrder}
            currencySymbol={storeConfig?.currency || '$'}
          />
        </ModalPortal>
      )}
      {transferOrderModal && selectedOrder._id && (
        <TransferOrderModal
          toggleModal={handleTransferOrderModalClose}
          orderId={selectedOrder._id}
        />
      )}
      <PrinterProvider>
        <PrintOrderModal selectedOrder={selectedOrder} />
      </PrinterProvider>
      {paymentViewModal && (
        <PaymentViewModal
          toggleModal={handlePaymentModalClose}
          // openPaymentViewModal={handleOpenPaymentViewModal}
          currentOrder={selectedOrder}
          orderTimes={''}
          isOrdersPage={true}
          openCashAmountPaidModal={handleCashAmountSelectedModal}
          handleCashAmountChange={handleCashAmountChange}
          handleCashPaid={handleCashPaid}
          handleSplitPaymentClick={handleSplitPaymentClick}
        />
      )}
      {showCashAmountModal && (
        <CashAmountSelectedModal
          orderId={selectedOrder._id}
          toggleModal={handleCashAmountModalClose}
          modalStatus={!paymentViewModal && showCashAmountModal}
          cashAmountChange={cashAmountChange}
          cashPaid={cashPaid}
          isOrdersPage={true}
          title={cashModalTitle}
        />
      )}
      {cancelOrderState.showModal ? (
        <CancelOrderAlertModal
          orderId={selectedOrderId}
          modalStatus={cancelOrderState?.showModal}
          toggleModal={handleAbortCancelClick}
          handleCancel={handleAbortCancelClick}
          handleProceed={handleConfirmCancelOrder}
          loading={cancelOrderState.state === 'loading'}
        />
      ) : null}
      {showSplitPaymentModal ? (
        <SplitPaymentsRoot
          isOrdersPage={true}
          currentOrder={selectedOrder}
          closeFlowStatus={handleSplitPaymentModalClose}
        />
      ) : null}
      {refundOrderModal.showModal && (
        <RefundProvider>
          <RefundAmountModal {...refundOrderModal} />
          <RefundPhoneModal />
          <RefundErrorModal {...refundOrderModal} />
          <RefundIssuedModal {...refundOrderModal} />
          <StepModal {...refundOrderModal}>
            <StepModal.Step>
              <RefundReasonModal {...refundOrderModal} />
            </StepModal.Step>
            <StepModal.Step>
              <RefundAffectedItemsModal {...refundOrderModal} />
            </StepModal.Step>
            <StepModal.Step>
              <RefundDetailsModal {...refundOrderModal} />
            </StepModal.Step>
          </StepModal>
        </RefundProvider>
      )}
      {pinModal ? (
        <StaffPinModal
          modalStatus={pinModal.showModal}
          onModalClose={() =>
            dispatch({ type: 'togglePinModal', payload: { showModal: false } })
          }
          onComplete={handlePinComplete}
          errorMessage={errorMessage}
          wiggleEffect={wiggleEffect}
          setWiggleEffect={setWiggleEffect}
        />
      ) : null}
      {accessDenied && (
        <AccessDeniedModal
          handleModalClose={setShowAccessDenied}
          message={errorMessage}
        />
      )}
      <ModalViewOrdersTime
        timeType={'pickUpTime'}
        averageVal={'32 mins'}
        modalTitle={'Collection Time'}
        id={1}
      />
      <ModalViewOrdersTime
        timeType={'deliveryTime'}
        averageVal={'32 mins'}
        modalTitle={'Delivery Time'}
        id={2}
      />
      <ModalPortal>
        <SplitBillDiscountModal
          isOrderScreen={true}
          selectedOrder={selectedOrder}
        />
      </ModalPortal>
    </>
  );
};

export default ModalsWrapper;
