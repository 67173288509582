import { detectMergeRequired } from '../orderEngine/utilities';
import { performMerge } from './utilities';
import orderEngine from '../orderEngine/index';

const addItemToOrder = (
  currentOrder,
  item,
  allSpecials,
  orderSetup,
  productSetup,
  allSuburbs,
  publicHolidays,
  storeConfig
) => {
  let isItemmerged = false;
  let menuItems = [...currentOrder.menuItems];
  let currentOrderObj = { ...currentOrder };
  let existingItems = menuItems.filter((menuItem) => {
    if (orderSetup?.isSpecialsManuallyDisabled) {
      if (
        menuItem._id === item._id &&
        item?.isSpecialItem === menuItem?.isSpecialItem
      ) {
        if (!item.isHalf && !item.isQuarter) {
          return true;
        }
      }
    } else {
      if (menuItem._id === item._id) {
        if (
          !item.isHalf &&
          !item.isQuarter &&
          menuItem?.isHalf === item?.isHalf
        ) {
          return true;
        }
      }
    }

    return false;
  });

  if (existingItems?.length) {
    existingItems.forEach((existingItem) => {
      if (!isItemmerged) {
        if (detectMergeRequired(item, existingItem)) {
          let mergedItem = performMerge(item, existingItem);
          menuItems = menuItems.map((menuItem) => {
            if (
              menuItem._id === item._id &&
              mergedItem.orderIndex === menuItem.orderIndex
            ) {
              return {
                ...menuItem,
                ...mergedItem,
              };
            }
            return {
              ...menuItem,
            };
          });
          isItemmerged = true;
          currentOrderObj = {
            ...currentOrderObj,
            menuItems: [...menuItems],
          };
        } else {
          currentOrderObj = {
            ...currentOrderObj,
            menuItems: [...menuItems, item],
          };
        }
      }
    });
  } else {
    currentOrderObj = {
      ...currentOrderObj,
      menuItems: [...menuItems, { ...item, hasOptions: true }],
    };
  }

  let newOrder = orderEngine(
    currentOrderObj,
    allSpecials,
    orderSetup,
    productSetup,
    allSuburbs,
    publicHolidays,
    storeConfig
  );

  return newOrder;
};

export default addItemToOrder;
