import { CoursesPopup, ModalPortal } from 'components';
import { API_URL } from 'configuration/apiUrl';
import { OrderTypeIdEnum, paymentTypeEnum } from 'configuration/enums';
import { nextOrderConfig } from 'configuration/nextOrderConfig';
import dayjs from 'dayjs';
import { useModal } from 'hooks';
import { useApi } from 'hooks/useApi';
import useOrderTouched from 'hooks/useOrderTouched';
import { useGlobalModalContext } from 'modals/GlobalModalContext/useGlobalModalContext';
import { OrderSideModal } from 'modals/OrderSideModal';
import SimpleSelectTimeModal from 'modals/SimpleSelectTimeModal';
import SplitBillDiscountModal from 'modals/SplitBillDiscountModal';
import SurchargeModal from 'modals/SurchargeModal';
import moment from 'moment';
import orderEngine from 'orderEngine';
import {
  loadOrderPayments,
  toggleOrderModal,
  updateCurrentOrder,
  updateCurrentOrderDeliveryLocation,
  setActiveSeatId,
} from 'pages/Dashboard/action';
import {
  activeSpecialsSelector,
  currencySymbolSelector,
  orderSetupCashDrawerReminderSelector,
  payForDineinWhenOrderdingSelector,
  productSetupSelector,
  publicHolidaysSelector,
  selectedDrawerStatusIsActiveSelector,
  storeConfigSelector,
  storeCountryIdentifierSelector,
  suburbsSelector,
} from 'pages/Dashboard/selectors';
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import { kiosk_order_route } from 'constants/routesConsts';
import useIsKiosk from 'hooks/useIsKiosk';
import useMinOrderValue from 'hooks/useMinOrderValue';
import KioskAccessModal from 'modals/KioskAccessModal';
import { PickUpIcon } from 'nexticons/outline';
import { PhoneIcon, ChefIcon, FastFoodIcon } from 'nexticons/solid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { openModal } from 'reducers/modals';
import { getAmountWithCountryCurrency } from 'util/currencyFormat';
import orderAndPayPrice from 'util/orderAndPayPrice';
import { getTimestampForMomentDate } from 'util/timeHelper';
import { PosIconBurger } from '../../assets/icons/PosIconBurger';
import { PosIconLoading } from '../../assets/icons/PosIconLoading';
import { _auth, _firestore } from '../../firebase';
import GeneralModal from '../../modals/GeneralModal/index-new';
import { ItemManualPriceAmount } from '../../modals/ItemManualAmount';
import Button from '../Button';
import DeliveryDetailsModal from '../DeliveryDetailsModal';
import EnterAddressManuallyModal from '../EnterAddressManuallyModal';
import OrderAddressBar from '../OrderAddressBar';
import { OrderBarGuest } from '../OrderBarGuest';
import OrderBarMobile from '../OrderBarMobile';
import { OrderBarNotes } from '../OrderBarNotes';
import { OrderBarTableNumber } from '../OrderBarTableNumber';
import { OrderDataPicker } from '../OrderDataPicker';
import CartItems from './CartItems';
import TableTimer from './TableTimer/TableTimer';
import { ChairIcon } from 'nexticons/solid';
import CartTag from './CartTag';
import { v4 as uuidv4 } from 'uuid';
import GuestDropDown from './GuestDropDown/Index';
import GuestCounter from './GuestDropDown/GuestCounter';
import { updateNumOfGuests } from './GuestDropDown/GuestDropDown.service';
import { getOccupancyAPI } from 'services/dineInServices';
import CartSeatHeader, { generateColorCode } from './CartSeatHeader';
import CoursesModal from './CoursesModal';
import { ScrollableBoxUI } from 'UI/components';
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';

const updateOrder = async (id, payload, storeConfig) => {
  let requestObj = {
    order: {
      ...payload,
      paymentType: '0',
      storeId: storeConfig?.storeId,
      restaurantId: storeConfig.restaurantId,
      countryIdentifier: storeConfig.countryIdentifier,
      doNotUpdateOrderStatus: false,
    },
    payment: {
      type: '0',
      amount: 0,
    },
  };

  await fetch(`${nextOrderConfig.cloudFunctionUrl}/placeOrder3DSecure`, {
    method: 'post',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...requestObj,
    }),
  });

  return _firestore.collection('Orders').doc(id).update(payload);
};

export const OrderBar = ({
  onPayClick,
  title,
  isOrderTypeDelivery,
  isOrderTypeWalkin,
  isOrderTypeDinein,
  orderSetup,
  orderTimes,
  isAddingToTable,
  parentPage,
  isPaymentLoading,
  extendedClassName = '',
}) => {
  const isKiosk = useIsKiosk();

  const [openGuestCounter, setOpenGuestCounter] = useState(false);
  const currentOrder = useSelector(
    (state) => state.dashboardReducer.currentOrder
  );
  const [activeCartTags, setActiveCartTags] = useState(
    !!currentOrder?.allSeatsData ? ['Seats'] : []
  );
  const [seatsData, setSeatsData] = useState(currentOrder?.allSeatsData || []);
  const [isCoursesModalOpen, setIsCoursesModalOpen] = useState(false);
  const disableOrderPerSeat = useSelector(
    (state) => state.dashboardReducer.orderSetup?.disableOrderPerSeat
  );
  const [expandedDropdowns, setExpandedDropdowns] = useState(['Shared']);
  const [selectedSeatId, setSelectedSeatId] = useState('Shared');

  const timeModalActions = useModal('timeModal')?.[1];
  const fullScreen = useSelector((s) => s.dashboardReducer.fullScreen);
  const payForDineinWhenOrderding = useSelector(
    payForDineinWhenOrderdingSelector
  );
  const [openEditDeliveryFeeModal, setOpenEditDeliveryFeeModal] =
    useState(false);
  const intialorderSetup = useSelector((s) => s.dashboardReducer.orderSetup);
  const primaryThemeColour = useSelector(
    (s) => s.storeWebsiteLoaderDataReducer.primaryThemeColour
  );
  const dineInOccupancies = useSelector(
    (state) => state.notificationsReducer?.dineInOccupancies
  );

  const [menuItemsWithCourseStatus, setMenuItemsWithCourseStatus] = useState(
    []
  );

  const specials = useSelector(activeSpecialsSelector);
  const productSetup = useSelector(productSetupSelector);
  const suburbs = useSelector(suburbsSelector);
  const publicHolidays = useSelector(publicHolidaysSelector);
  const accessToken = useSelector(
    (state) => state.dashboardReducer.accessToken
  );
  const [
    {
      coursesPopupOpen,
      isShowModal,
      isShowManualAddressModal,
      // isShowTimeModal,
      loading,
    },
    setState,
  ] = useReducer((s, a) => ({ ...s, ...a }), {});
  const cartScrollRef = useRef();
  const guestDropdownRef = useRef();
  const orderBarHeaderRef = useRef();
  const orderBarFooterRef = useRef();
  const { handleGlobalModal } = useGlobalModalContext();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const attachRequest = useApi('POST', `${API_URL}/orders/v1/courses`)?.[1];
  const {
    pickUpTime,
    deliveryTime,
    defaultDeliveryTime = 0,
    defaultPickUpTime = 0,
  } = intialorderSetup;
  const [showOrderBar, setShowOrderBar] = useState(false);
  const [waitingTime, setWaitingTime] = useState('');
  const [waitingTimeStamp, setWaitingTimeStamp] = useState('');
  const storeConfig = useSelector(storeConfigSelector);
  const currencySymbol = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const cashDrawerReminder = useSelector(orderSetupCashDrawerReminderSelector);
  const acceptedCall = useSelector(
    (state) => state.dashboardReducer.acceptedCall
  );

  const selectedDrawerStatusIsActive = useSelector(
    selectedDrawerStatusIsActiveSelector
  );
  const hasItems = useMemo(
    () => currentOrder.menuItems?.length > 0,
    [currentOrder.menuItems?.length]
  );

  const referrer = useMemo(
    () => (location.state ? location.state.referrer : null),
    [location.state]
  );

  const selectedTableOccupancyDetails = dineInOccupancies?.find(
    (occupancy) => currentOrder.occupancyId === occupancy.id
  );
  const totalSeats = selectedTableOccupancyDetails?.numPeople || 0;
  const [numOfGuests, setNumOfGuests] = useState(
    selectedTableOccupancyDetails?.numPeople || 0
  );

  const [prevNumOfGuests, setPrevNumOfGuests] = useState(
    selectedTableOccupancyDetails?.numPeople || 0
  );

  const [orderTouched, setNewItemsExist] = useOrderTouched(currentOrder);

  const minOrderValue = useMinOrderValue({ currentOrder, orderSetup });

  const isMatchesMinOrderValue = isOrderTypeDelivery
    ? minOrderValue <=
      Number(
        currentOrder?.payableAmount -
          (Number(currentOrder?.deliveryCost) || 0) ?? 0
      )
    : true;

  const [alreadyPaidAmount, isAmountTheSame] = useMemo(() => {
    if (currentOrder.payments && currentOrder.payments.length > 0) {
      let paidAmount = 0;
      currentOrder.payments.forEach((payment) => {
        paidAmount = paidAmount + Number(payment.amount);
      });

      return [Number(paidAmount.toFixed(2)), false];
    }
    return [0, false];
  }, [currentOrder.payments]);

  useEffect(() => {
    const initialMenuItemsWithCourseStatus =
      currentOrder?.menuItems?.map((item) => {
        return {
          ...item,
          courseStatus: item?.courseStatus || 'send',
        };
      }) || [];

    setMenuItemsWithCourseStatus(initialMenuItemsWithCourseStatus);
  }, [currentOrder?.menuItems]);

  useEffect(() => {
    let interval = null;
    if (currentOrder.firstName && pickUpTime && deliveryTime) {
      interval = setInterval(() => {
        let updatedWaitingTime = '';
        if (currentOrder?.selectedTimeDiffInMins) {
          updatedWaitingTime = dayjs()
            .add(Number(currentOrder?.selectedTimeDiffInMins), 'm')
            .format('YYYY-MM-DD HH:mm');
          setWaitingTime(updatedWaitingTime);
          setWaitingTimeStamp(dayjs(updatedWaitingTime).valueOf());
        } else {
          if (isOrderTypeDelivery) {
            updatedWaitingTime = dayjs()
              .add(Number(deliveryTime || defaultDeliveryTime), 'm')
              .format('YYYY-MM-DD HH:mm');
            setWaitingTime(updatedWaitingTime);
            setWaitingTimeStamp(dayjs(updatedWaitingTime).valueOf());
          } else {
            updatedWaitingTime = dayjs()
              .add(Number(pickUpTime || defaultPickUpTime), 'm')
              .format('YYYY-MM-DD HH:mm');
            setWaitingTime(updatedWaitingTime);
            setWaitingTimeStamp(dayjs(updatedWaitingTime).valueOf());
          }
        }
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [
    currentOrder?.firstName,
    pickUpTime,
    deliveryTime,
    currentOrder?.selectedTimeDiffInMins,
  ]);

  useEffect(() => {
    if (waitingTime && !currentOrder?.iteration > 0) {
      dispatch(
        updateCurrentOrder({
          ...currentOrder,
          deliveryDate: waitingTime,
          deliveryDateTimestamp: waitingTimeStamp,
        })
      );
    }
    // eslint-disable-next-line
  }, [waitingTime, waitingTimeStamp]);

  useEffect(() => {
    dispatch(
      updateCurrentOrder({
        'idempotent-key': uuidv4(),
      })
    );
  }, []);

  useEffect(() => {
    if (currentOrder._id) dispatch(loadOrderPayments(currentOrder._id));
    // eslint-disable-next-line
  }, [currentOrder._id]);

  const handlePay = useCallback(
    async (cb, extraOptions) => {
      setState({ loading: true });

      const difference =
        parseFloat(currentOrder.payableAmount) - alreadyPaidAmount;
      const isUnpaid =
        currentOrder.paymentType === paymentTypeEnum.UNPAID ||
        !currentOrder.paymentType;
      const isDineIn = currentOrder.orderType === OrderTypeIdEnum.DINE_IN;

      if (isDineIn) {
        if (!orderTouched) {
          history.push('/table-arrangement');
          return;
        }
        onPayClick(async (result) => {
          if (typeof cb === 'function') {
            await cb(result);
          }
          history.push('/table-arrangement');
          // setState({ loading: false });
        }, extraOptions);
      } else if (isUnpaid || Math.abs(difference) > 0) {
        onPayClick();
      } else {
        // save updated order and go back
        try {
          await updateOrder(currentOrder._id, currentOrder, storeConfig);
        } catch (err) {
          // probably doc doesn't exist
          // if it's dine-in, create new order
          console.info('updateOrder error');
          console.error(err);
        } finally {
          if (referrer) {
            history.push(referrer, { orderID: currentOrder._id });
          } else {
            history.push('/');
          }
        }

        setState({ loading: false });
      }
    },
    [
      alreadyPaidAmount,
      currentOrder,
      history,
      onPayClick,
      orderTouched,
      referrer,
      storeConfig,
    ]
  );

  const courseIds = useMemo(
    () =>
      new Set(
        currentOrder.orderType === '3'
          ? currentOrder.menuItems.map(({ courseId }) => courseId)
          : []
      ),
    [currentOrder.menuItems, currentOrder.orderType]
  );

  const hasCourses = useMemo(
    () => [...courseIds].filter(Boolean).length > 0,
    [courseIds]
  );

  const orderAndPayAmount = orderAndPayPrice({
    currentOrder,
    isMatchesMinOrderValue,
    alreadyPaidAmount,
    storeCountryIdentifier,
    currencySymbol,
    minOrderValue,
  });

  let orderPayLabel =
    isOrderTypeDinein &&
    Number(currentOrder.payableAmount) !== alreadyPaidAmount
      ? `Add To Table ${
          currentOrder.menuItems?.length && currentOrder.payableAmount
            ? `${getAmountWithCountryCurrency({
                amount: Number(currentOrder.payableAmount),
                locale: `en-${storeCountryIdentifier}`,
                currencyCode: currencySymbol,
              })} ${
                !isMatchesMinOrderValue
                  ? '(Min. ' +
                    getAmountWithCountryCurrency({
                      amount: Number(minOrderValue),
                      locale: `en-${storeCountryIdentifier}`,
                      currencyCode: currencySymbol,
                    }) +
                    ')'
                  : ''
              }`
            : ''
        }`
      : Number(currentOrder.payableAmount) > alreadyPaidAmount &&
        !isAmountTheSame &&
        !isOrderTypeDinein
      ? `Order & Pay ${orderAndPayAmount}`
      : isAmountTheSame
      ? `Save Order Changes ${
          currentOrder.menuItems?.length && currentOrder.payableAmount
            ? `${getAmountWithCountryCurrency({
                amount: Number(
                  Math.abs(
                    alreadyPaidAmount - Number(currentOrder.payableAmount)
                  )
                ),
                locale: `en-${storeCountryIdentifier}`,
                currencyCode: currencySymbol,
              })}`
            : ''
        }`
      : 'Save Order Changes';

  if (
    Number(currentOrder.payableAmount) !== alreadyPaidAmount &&
    payForDineinWhenOrderding
  ) {
    orderPayLabel = `Order & Pay ${
      currentOrder.menuItems?.length && currentOrder.payableAmount
        ? `${getAmountWithCountryCurrency({
            amount: Number(
              Number(currentOrder.payableAmount) - alreadyPaidAmount
            ),
            locale: `en-${storeCountryIdentifier}`,
            currencyCode: currencySymbol,
          })} ${
            !isMatchesMinOrderValue
              ? '(Min. ' +
                getAmountWithCountryCurrency({
                  amount: Number(minOrderValue),
                  locale: `en-${storeCountryIdentifier}`,
                  currencyCode: currencySymbol,
                }) +
                ')'
              : ''
          }`
        : ''
    }`;
  }

  if (
    currentOrder._id &&
    hasCourses &&
    // currentOrder.calledCourses?.length > 0 &&
    currentOrder.calledCourses?.length !== courseIds.size &&
    courseIds.size > 1
  ) {
    orderPayLabel = 'Send To Kitchen';
  }

  const displayText = isOrderTypeDelivery
    ? `Delivery by ${
        currentOrder.deliveryDate
          ? moment(currentOrder.deliveryDate, 'YYYY-MM-DD HH:mm').format(
              'ddd DD MMM hh:mm A'
            )
          : orderSetup?.deliveryTime + ' mins'
      }`
    : `Collection in ${
        currentOrder.deliveryDate
          ? moment(currentOrder.deliveryDate, 'YYYY-MM-DD HH:mm').format(
              'ddd DD MMM hh:mm A'
            )
          : orderSetup?.pickUpTime + ' minutes'
      }`;

  const handleChange = (event) => {
    dispatch(
      updateCurrentOrderDeliveryLocation({
        [event.target.name]: event.target.value,
      })
    );
  };

  const errorMessage = 'This Area is not serviceable by restaurant.';

  const deliveryLabel = `Delivery ${getAmountWithCountryCurrency({
    amount: Number(currentOrder?.deliveryCost),
    locale: `en-${storeCountryIdentifier}`,
    currencyCode: currencySymbol,
  })}`;

  const specialsLabel = Number(currentOrder?.specialDiscount)
    ? `Specials - ${getAmountWithCountryCurrency({
        amount: Number(currentOrder?.specialDiscount),
        locale: `en-${storeCountryIdentifier}`,
        currencyCode: currencySymbol,
      })}`
    : '';

  const publicHolidaySurchargeLabel = Number(
    currentOrder?.publicHolidaySurcharge
  )
    ? `Public Holiday Surcharge - ${getAmountWithCountryCurrency({
        amount: Number(currentOrder?.publicHolidaySurcharge),
        locale: `en-${storeCountryIdentifier}`,
        currencyCode: currencySymbol,
      })}`
    : '';

  const serviceSurchargeLabel = Number(currentOrder?.serviceCharge)
    ? `Service charge - ${getAmountWithCountryCurrency({
        amount: Number(currentOrder?.serviceCharge),
        locale: `en-${storeCountryIdentifier}`,
        currencyCode: currencySymbol,
      })}`
    : '';

  const discountLabel = Number(currentOrder?.discount)
    ? `Discount - ${getAmountWithCountryCurrency({
        amount: Number(currentOrder?.discount),
        locale: `en-${storeCountryIdentifier}`,
        currencyCode: currencySymbol,
      })}`
    : '';

  const voucherLabel = Number(currentOrder?.voucherDiscount)
    ? `Voucher - ${getAmountWithCountryCurrency({
        amount: Number(currentOrder?.voucherDiscount),
        locale: `en-${storeCountryIdentifier}`,
        currencyCode: currencySymbol,
      })}`
    : '';

  useEffect(() => {
    if (
      orderTimes &&
      orderTimes.length &&
      !currentOrder.deliveryDateTimestamp &&
      !currentOrder.deliveryDate
    ) {
      let timeArray = [];
      timeArray = orderTimes;
      let _currentOrder = {};
      if (!currentOrder.deliveryDateTimestamp && !currentOrder.deliveryDate) {
        const defaultTime = timeArray[0].times[0];
        const defaultTimeString = `${defaultTime.timeObjFinal} ${defaultTime.displayForUse}`;
        // find the orderTime Object for the selected order time,
        // to get the shiftId and either the order is a pre-order or ASAP
        let deliveryDate = moment(
          defaultTimeString,
          'YYYY-MM-DD HH:mm A'
        ).format('YYYY-MM-DD HH:mm');
        let deliveryDateTimestamp = getTimestampForMomentDate(
          moment(defaultTimeString, 'YYYY-MM-DD HH:mm A'),
          storeConfig.timeZone,
          'YYYY-MM-DD HH:mm'
        );
        let shiftId = defaultTime.shiftId;
        let isFutureDeliveryDate = defaultTime.display !== 'ASAP';
        _currentOrder = {
          ...currentOrder,
          deliveryDate,
          deliveryDateTimestamp,
          shiftId,
          isFutureDeliveryDate,
        };
      }
      const newCurrentOrder = orderEngine(
        _currentOrder,
        specials,
        orderSetup,
        productSetup,
        suburbs,
        publicHolidays,
        storeConfig
      );

      if (
        newCurrentOrder.deliveryDateTimestamp &&
        newCurrentOrder.deliveryDate
      ) {
        dispatch(updateCurrentOrder(newCurrentOrder));
      }
    }
  }, [
    orderTimes,
    dispatch,
    orderSetup,
    productSetup,
    currentOrder,
    publicHolidays,
    specials,
    storeConfig,
    suburbs,
  ]);

  useEffect(() => {
    if (loading !== isPaymentLoading) {
      setState({ loading: isPaymentLoading });
    }
  }, [isPaymentLoading, loading]);

  const setStateHandler = useCallback(
    (key) => (value) => setState({ [key]: value }),
    [setState]
  );
  const setToTrue = useCallback(
    (key) => () => setStateHandler(key)(true),
    [setStateHandler]
  );
  const setToFalse = useCallback(
    (key) => () => setStateHandler(key)(false),
    [setStateHandler]
  );
  const handleManualAddressClick = useCallback(() => {
    setState({ isShowManualAddressModal: true, isShowModal: true });
  }, [setState]);
  const handlePayButtonClick = useCallback(() => {
    if (
      currentOrder._id &&
      hasCourses &&
      courseIds.size > 1 &&
      // currentOrder.calledCourses?.length > 0 &&
      currentOrder.calledCourses?.length !== courseIds.size
    ) {
      setState({ coursesPopupOpen: true });
    } else {
      // check also for the "cashDrawerReminder" flag
      if (!selectedDrawerStatusIsActive && cashDrawerReminder) {
        handleGlobalModal({
          title: 'You need to start the Connected Cash Draw',
          message:
            'To keep proper track of transactions, please go to Cashier and start the connected drawer. If there is no drawer yet selected, please select your drawer using the Select Drawer function at the top of the page.',
          dismissButtonHandler: handlePay,
        });
      } else {
        setTimeout(() => {
          handlePay(async (result) => {
            if (hasCourses && result.orderId) {
              const attachData = {
                orderId: result.orderId,
              };

              await attachRequest(attachData);
            }
          });
        }, 0);
      }
    }
  }, [
    attachRequest,
    cashDrawerReminder,
    courseIds.size,
    currentOrder,
    dispatch,
    handleGlobalModal,
    handlePay,
    hasCourses,
    selectedDrawerStatusIsActive,
  ]);

  const handleEditDeliveryFee = (data) => {
    let updatedPayableAmount = 0;
    const oldDeliveryCost =
      currentOrder.oldDeliveryCost || currentOrder?.deliveryCost;
    const deliveryCost = data?.updatedValue;
    if (currentOrder?.deliveryCost > data?.updatedValue) {
      const deliveryCostDifference =
        Number(currentOrder?.deliveryCost) - Number(data?.updatedValue);
      updatedPayableAmount =
        Number(currentOrder?.payableAmount) - Number(deliveryCostDifference);
    } else if (currentOrder?.deliveryCost < data?.updatedValue) {
      const deliveryCostDifference =
        Number(data?.updatedValue) - Number(currentOrder?.deliveryCost);
      updatedPayableAmount =
        Number(currentOrder?.payableAmount) + Number(deliveryCostDifference);
    }

    dispatch(
      updateCurrentOrder({
        payableAmount: updatedPayableAmount.toFixed(2),
        oldDeliveryCost,
        deliveryCost,
      })
    );

    setOpenEditDeliveryFeeModal(false);
  };

  const renderToggleOrderModal = () => {
    if (!isKiosk) {
      dispatch(toggleOrderModal(true));
    } else {
      dispatch(openModal('staffPinModal'));
    }
  };

  const toggleCourseModal = (isOpened) => {
    setIsCoursesModalOpen(!isOpened);

    const cartTags = activeCartTags;
    if (activeCartTags.includes('courses')) {
      const index = cartTags.indexOf('courses');
      if (index > -1) {
        cartTags.splice(index, 1);
      }

      setActiveCartTags([...cartTags]);
    }
  };

  const handleCoursesTagOnClick = () => {
    const cartTags = activeCartTags;
    if (activeCartTags.includes('courses')) {
      const index = cartTags.indexOf('courses');
      if (index > -1) {
        cartTags.splice(index, 1);
      }
      toggleCourseModal(isCoursesModalOpen);
      setActiveCartTags([...cartTags]);
    } else {
      toggleCourseModal(isCoursesModalOpen);
      cartTags.push('courses');
      setActiveCartTags([...cartTags]);
    }
  };

  const handleSeatsTagOnClick = () => {
    const cartTags = activeCartTags;
    if (activeCartTags.includes('Seats')) {
      const index = cartTags.indexOf('Seats');
      if (index > -1) {
        cartTags.splice(index, 1);
      }
      setSeatsData([]);

      setActiveCartTags([...cartTags]);
    } else {
      cartTags.push('Seats');
      setActiveCartTags([...cartTags]);

      const initialSeatIds = [];

      if (currentOrder?.allSeatsData?.length > 0) {
        setSeatsData([...currentOrder?.allSeatsData]);
      } else {
        let menuItems = currentOrder?.menuItems || [];

        for (let seat = 0; seat < totalSeats; seat++) {
          initialSeatIds.push({
            seatName: `Seat ${seat + 1}`,
            seatId: uuidv4(),
          });
        }
        if (menuItems.length > 0) {
          menuItems = menuItems?.map((item) => {
            return { ...item, activeSeatId: 'Shared' };
          });
        }
        setSeatsData([...initialSeatIds]);
        dispatch(
          updateCurrentOrder({
            ...currentOrder,
            allSeatsData: [...initialSeatIds],
            menuItems: [...menuItems],
          })
        );
      }
    }
  };

  const handleSelectedSection = (seatId) => {
    setSelectedSeatId(seatId);
    dispatch(setActiveSeatId(seatId));
  };

  const toggleGuestCounterClick = async () => {
    setOpenGuestCounter(!openGuestCounter);

    //on Modal Close
    if (openGuestCounter && prevNumOfGuests !== numOfGuests) {
      if (currentOrder?.allSeatsData?.length > 0) {
        if (numOfGuests > prevNumOfGuests) {
          //add new seats
          const newSeats = [];
          for (let seat = prevNumOfGuests; seat < numOfGuests; seat++) {
            newSeats.push({
              seatName: `Seat ${seat + 1}`,
              seatId: uuidv4(),
            });
          }

          const updatedSeats = [...currentOrder?.allSeatsData, ...newSeats];

          dispatch(
            updateCurrentOrder({
              ...currentOrder,
              allSeatsData: [...updatedSeats],
            })
          );
          setSeatsData([...updatedSeats]);
        } else if (numOfGuests < prevNumOfGuests) {
          //remove seats
          const updatedSeats = [...currentOrder?.allSeatsData].slice(
            0,
            numOfGuests
          );

          const seatIdsExcluded = [
            ...currentOrder?.allSeatsData?.map((item) => item.seatId),
          ].slice(numOfGuests, prevNumOfGuests);

          const updateMenuItemSeatIds = currentOrder?.menuItems?.map((item) => {
            if (
              item?.activeSeatId &&
              seatIdsExcluded.includes(item?.activeSeatId)
            ) {
              item.activeSeatId = 'Shared';
            }
            return item;
          });

          dispatch(
            updateCurrentOrder({
              ...currentOrder,
              allSeatsData: [...updatedSeats],
              menuItems: [...updateMenuItemSeatIds],
            })
          );
          setSeatsData([...updatedSeats]);
        }
      } else {
      }

      const response = await updateNumOfGuests(
        numOfGuests,
        currentOrder?.staffId || '',
        currentOrder?.occupancyId || ''
      );

      if (!!response?.success) {
        const { data } = await getOccupancyAPI({
          storeId: storeConfig.storeId,
          accessToken,
        });

        dispatch({
          type: 'UPDATE_DINE_IN_OCCUPANCIES_STATUS',
          payload: data.dineInOccupancies,
        });
      }
    } else {
      setPrevNumOfGuests(numOfGuests);
      setNumOfGuests(selectedTableOccupancyDetails?.numPeople || 0);
    }
  };

  const handleCounterClick = (type) => {
    if (type === '-') {
      if (numOfGuests > 0) {
        setNumOfGuests(numOfGuests - 1);
      }
    } else {
      setNumOfGuests(numOfGuests + 1);
    }
  };

  useEffect(() => {
    if (
      !disableOrderPerSeat &&
      !(seatsData.length > 0) &&
      isOrderTypeDinein &&
      totalSeats > 0
    ) {
      handleSeatsTagOnClick();
    }
  }, [totalSeats]);

  useEffect(() => {
    if (seatsData.length > 0 && isOrderTypeDinein) {
      dispatch(setActiveSeatId('Shared'));
    }
  }, [seatsData]);

  const handleOnDragEnd = (dragDetails) => {
    const { destination, source, draggableId } = dragDetails;
    const cartId = destination?.droppableId;
    const sourceCartId = source?.droppableId;

    let updatedMenuItem = [];
    if (draggableId?.includes('-')) {
      const orderIndexes = draggableId?.split('-');
      const menuItem = currentOrder.menuItems.filter((item) => {
        return orderIndexes.includes(`${item.orderIndex}`);
      });
      updatedMenuItem = currentOrder?.menuItems?.map((item) => {
        let updatedItem = { ...item };
        menuItem.forEach((mItem) => {
          if (item.orderIndex === mItem.orderIndex) {
            updatedItem = {
              ...updatedItem,
              activeSeatId: cartId || sourceCartId,
            };
          }
        });
        return updatedItem;
      });
    } else {
      const menuItem = currentOrder?.menuItems.find(
        (item) => item.orderIndex === Number(draggableId)
      );
      updatedMenuItem = currentOrder?.menuItems?.map((item) => {
        let updatedActiveSeatId = item?.activeSeatId || '';
        if (menuItem?.orderIndex === item?.orderIndex) {
          updatedActiveSeatId = cartId || sourceCartId;
        }
        return {
          ...item,
          activeSeatId: updatedActiveSeatId,
        };
      });
    }
    setNewItemsExist(true);
    dispatch(
      updateCurrentOrder({
        ...currentOrder,
        menuItems: updatedMenuItem,
      })
    );
  };

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {isKiosk && <KioskAccessModal />}
        <div
          className={`order-bar ${extendedClassName} ${
            showOrderBar &&
            (location.pathname === kiosk_order_route ||
              location.pathname === '/kiosk/pickup/order' ||
              location.pathname === '/kiosk/delivery/order')
              ? 'kiosk-screen__order-bar--show'
              : ''
          }`}
          style={{
            display: 'flex',
            flexFlow: 'column',
            justifyContent:
              seatsData.length > 0 ? 'flex-start' : 'space-between',
          }}
        >
          <div className="order-bar-top" ref={orderBarHeaderRef}>
            <div className="order-bar-header">
              <h3 className="title">{title}</h3>
              <Button
                title="Change"
                className="order-burger-btn"
                onClick={renderToggleOrderModal}
              >
                <PosIconBurger mainColor="#363636" darkModeColor="#ffffff" />
              </Button>
            </div>
            {isOrderTypeDinein && (
              <div className="dineInHeader">
                <div>
                  <TableTimer
                    selectedTableOccupancyDetails={
                      selectedTableOccupancyDetails
                    }
                  />
                </div>
                {!currentOrder?.isSplitPayment &&
                  !currentOrder?.payments?.length > 0 && (
                    <div ref={guestDropdownRef} style={{ paddingLeft: '10px' }}>
                      <GuestDropDown
                        isDisabled={!activeCartTags.includes('Seats')}
                        onClick={toggleGuestCounterClick}
                        isModalOpen={openGuestCounter}
                      />
                    </div>
                  )}
              </div>
            )}

            <div className="pickup-details-wrap">
              <ul className="pickup-details-list">
                {isOrderTypeDinein && (
                  <li>
                    <OrderBarTableNumber currentOrder={currentOrder} />
                  </li>
                )}
                {!isOrderTypeDinein && (
                  <li>
                    <div className="guest-name-contianer">
                      {!!acceptedCall?.id && (
                        <div className="on-Call-container">
                          <div className="on-call-icon-container">
                            <div className="phone-icon">
                              <PhoneIcon />
                            </div>
                          </div>
                        </div>
                      )}
                      <OrderBarGuest />
                    </div>
                  </li>
                )}
                {!(isOrderTypeWalkin || isOrderTypeDinein) && (
                  <li>
                    <OrderBarMobile currentOrder={currentOrder} />
                  </li>
                )}
                {isOrderTypeDelivery && (
                  <li>
                    <OrderAddressBar
                      address={`${currentOrder?.unit || ''} ${
                        currentOrder.address
                      }`}
                      onClick={setToTrue('isShowModal')}
                    />
                  </li>
                )}
                {!isOrderTypeDinein && (
                  <li>
                    <OrderDataPicker
                      currentOrder={currentOrder}
                      displayText={displayText}
                      onClick={timeModalActions.openModal}
                    />
                  </li>
                )}
                <li>
                  <OrderBarNotes currentOrder={currentOrder} />
                </li>
                {isOrderTypeDinein && (
                  <li>
                    <div className="cart-tag-container">
                      <CartTag
                        isActive={activeCartTags.includes('Seats')}
                        label="Seats"
                        customIcon={<ChairIcon width={18} color="#067aff" />}
                        onClick={handleSeatsTagOnClick}
                        customClass="is-seats-tag-selected"
                        customSvgClass="custom-svg-seats-style"
                      />
                      <CartTag
                        isActive={activeCartTags.includes('courses')}
                        label="Courses"
                        customIcon={<FastFoodIcon width={18} color="#fd6c00" />}
                        onClick={handleCoursesTagOnClick}
                        customClass="is-courses-tag-selected"
                        customSvgClass="custom-svg-courses-style"
                      />
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="cart-container">
            {isOrderTypeDinein && seatsData.length > 0 && (
              <Droppable droppableId={'Shared'}>
                {(provided, snapshot) => {
                  return (
                    <div
                      className="seat-section-container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <CartSeatHeader
                        provided={provided}
                        seat={{ seatId: 'Shared', seatName: 'Shared' }}
                        selectedSeatId={selectedSeatId}
                        handleSelectedSection={handleSelectedSection}
                        setNewItemsExist={setNewItemsExist}
                      />

                      <div
                        onClick={() => {
                          setSelectedSeatId('Shared');
                          dispatch(setActiveSeatId('Shared'));
                        }}
                      >
                        <CartItems
                          provided={provided}
                          setNewItemsExist={setNewItemsExist}
                          currentOrder={currentOrder}
                          showPrice={false}
                          basePath={parentPage}
                          fullScreen={fullScreen}
                          cartId="Shared"
                          customEmptyState={<div />}
                          customStyle={{
                            height: '60%',
                            border:
                              selectedSeatId === 'Shared'
                                ? `2px solid #32b900`
                                : 'none',
                          }}
                        />
                      </div>
                    </div>
                  );
                }}
              </Droppable>
            )}

            {isOrderTypeDinein &&
              seatsData.length > 0 &&
              seatsData.map((seat) => {
                return (
                  <Droppable droppableId={seat.seatId}>
                    {(provided, snapshot) => (
                      <div
                        className="seat-section-container"
                        key={seat.seatId}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <CartSeatHeader
                          provided={provided}
                          seat={seat}
                          selectedSeatId={selectedSeatId}
                          handleSelectedSection={handleSelectedSection}
                          setNewItemsExist={setNewItemsExist}
                        />

                        <div
                          onClick={() => {
                            setSelectedSeatId(seat.seatId);
                            dispatch(setActiveSeatId(seat.seatId));
                          }}
                        >
                          <CartItems
                            provided={provided}
                            setNewItemsExist={setNewItemsExist}
                            currentOrder={currentOrder}
                            showPrice={false}
                            basePath={parentPage}
                            fullScreen={fullScreen}
                            cartId={seat.seatId}
                            customEmptyState={<div />}
                            customStyle={{
                              height: '50%',
                              border:
                                selectedSeatId === seat.seatId
                                  ? `2px solid ${generateColorCode(
                                      seat.seatId
                                    )}`
                                  : 'none',
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Droppable>
                );
              })}
          </div>

          {seatsData.length === 0 && (
            <Droppable droppableId={'Shared'}>
              {(provided, snapshot) => (
                <CartItems
                  provided={provided}
                  setNewItemsExist={setNewItemsExist}
                  currentOrder={currentOrder}
                  showPrice={false}
                  basePath={parentPage}
                  fullScreen={fullScreen}
                  disableDragAndDrop={true}
                />
              )}
            </Droppable>
          )}

          {hasItems && (
            <div
              style={{ position: 'relative' }}
              className="order-actions-btn-box"
              ref={orderBarFooterRef}
            >
              <div className="btn-box">
                {discountLabel && (
                  <Button title={discountLabel} className="btn-action">
                    {discountLabel}
                  </Button>
                )}
                {voucherLabel && (
                  <Button title={voucherLabel} className="btn-action">
                    {voucherLabel}
                  </Button>
                )}
                {specialsLabel && (
                  <Button title={specialsLabel} className="btn-action">
                    {specialsLabel}
                  </Button>
                )}
                {publicHolidaySurchargeLabel && (
                  <Button
                    title={publicHolidaySurchargeLabel}
                    className="btn-action"
                  >
                    {publicHolidaySurchargeLabel}
                  </Button>
                )}
                {serviceSurchargeLabel && (
                  <Button title={serviceSurchargeLabel} className="btn-action">
                    {serviceSurchargeLabel}
                  </Button>
                )}
                {isOrderTypeDelivery && (
                  <div className="delivery-btn-container">
                    <Button
                      title={deliveryLabel}
                      className="btn-action"
                      onClick={() =>
                        orderSetup?.editDeliveryCharge && !isKiosk
                          ? setOpenEditDeliveryFeeModal(true)
                          : {}
                      }
                    >
                      {deliveryLabel}
                      {orderSetup?.editDeliveryCharge && !isKiosk && (
                        <span className="edit-btn">Edit</span>
                      )}
                    </Button>
                  </div>
                )}
              </div>
              {!coursesPopupOpen && (
                <div className="btn-box">
                  <Button
                    title={orderPayLabel}
                    className="btn-action big width-100 blue"
                    onClick={handlePayButtonClick}
                    disabled={
                      !isMatchesMinOrderValue || isAddingToTable || loading
                    }
                    style={{
                      opacity:
                        isMatchesMinOrderValue && !isAddingToTable
                          ? '1'
                          : '0.6',
                    }}
                    kioskStyle={{
                      borderRadius: 8,
                      margin: '18px 10px',
                    }}
                    enableThemeColor
                  >
                    {orderPayLabel.replace('$0.00', '')}
                    {(isAddingToTable || loading) && (
                      <PosIconLoading
                        mainColor="white"
                        style={{ width: '18px', height: '18px' }}
                      />
                    )}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>

        {(location.pathname === kiosk_order_route ||
          location.pathname === '/kiosk/pickup/order' ||
          location.pathname === '/kiosk/delivery/order') && (
          <>
            <div
              className={`kiosk-screen__order-bar-overlay ${
                showOrderBar ? 'kiosk-screen__order-bar-overlay--show' : ''
              }`}
              onClick={() => setShowOrderBar(false)}
            />

            <div
              className={`kiosk-screen__bottom-panel ${
                !showOrderBar ? 'kiosk-screen__bottom-panel--show' : ''
              }`}
            >
              <div className="nav-bar kiosk-screen__empty" />
              <div className="kiosk-screen__bottom-panel-inner">
                <div className="kiosk-screen__total">
                  <PickUpIcon width={32} strokeWidth={1.25} />
                  <span className="kiosk-screen__total-text">
                    Total: {orderAndPayAmount}
                  </span>
                </div>
                <button
                  className="kiosk-screen__panel-btn"
                  style={{
                    backgroundColor: primaryThemeColour,
                  }}
                  onClick={() => setShowOrderBar(true)}
                >
                  View Order
                </button>
              </div>
            </div>
          </>
        )}

        <CoursesPopup
          handlePay={handlePay}
          coursesPopupOpen={coursesPopupOpen}
          setCloseCoursesPopup={setToFalse('coursesPopupOpen')}
          currentOrder={currentOrder}
          orderTouched={orderTouched}
          isAddingToTable={isAddingToTable}
          menuItemsWithCourseStatus={menuItemsWithCourseStatus}
        />

        <ModalPortal>
          <SimpleSelectTimeModal
            orderTimes={orderTimes}
            onConfirmClick={() => console.log('onConfirmClick')}
            orderBarTitle={title}
          />
          {orderTimes && isShowModal && (
            <DeliveryDetailsModal
              modalStatus={isShowModal}
              onBackClick={setToFalse('isShowModal')}
              onResetClick={() => {}}
              onChange={handleChange}
              currentOrder={currentOrder}
              onConfirmClick={setToFalse('isShowModal')}
              errorMessage={errorMessage}
              timeInMinutes={orderSetup?.deliveryTime}
              orderTimes={orderTimes}
              storeConfig={storeConfig}
              onSaveDeliveryDate={timeModalActions.openModal}
              isOrderTypeDelivery={isOrderTypeDelivery}
              handleManualAddressClick={handleManualAddressClick}
            />
          )}
        </ModalPortal>
        {isShowManualAddressModal && (
          <ModalPortal>
            <EnterAddressManuallyModal
              modalStatus={isShowManualAddressModal}
              toggleModal={setToFalse('isShowManualAddressModal')}
              handleConfirmClick={setToFalse('isShowManualAddressModal')}
              currentOrder={currentOrder}
              storeConfig={storeConfig}
              specials={specials}
              orderSetup={orderSetup}
              productSetup={productSetup}
              publicHolidays={publicHolidays}
              isEnvokedFromOrderBar={true}
            />
          </ModalPortal>
        )}
        <OrderSideModal />
        <ModalPortal>
          <SplitBillDiscountModal />
          <SurchargeModal />
          <GeneralModal
            id="eidt-delivery-fee"
            modalStatus={openEditDeliveryFeeModal}
            position={'center'}
            effect="move"
            boxWidth="435px"
            boxHeight="670px"
            backBtn="outside"
            backBtnFnc={() => setOpenEditDeliveryFeeModal(false)}
            keyboardVisibility={true}
          >
            <ItemManualPriceAmount
              id="delivery-fee"
              title="Edit Delivery Charges"
              applyButtonText="Apply Delivery Charges"
              originalTextLabel="Original Charges"
              price={currentOrder?.deliveryCost}
              originalPrice={
                currentOrder?.oldDeliveryCost || currentOrder?.deliveryCost
              }
              onApply={handleEditDeliveryFee}
              onBack={() => setOpenEditDeliveryFeeModal(false)}
              currencySymbol={currencySymbol}
              increaseAllowed={true}
            />
          </GeneralModal>
        </ModalPortal>
        {openGuestCounter && (
          <GuestCounter
            show={openGuestCounter}
            toggleModal={toggleGuestCounterClick}
            guestDropdownRef={guestDropdownRef}
            numOfGuest={numOfGuests}
            onClick={handleCounterClick}
          />
        )}

        {isCoursesModalOpen && (
          <CoursesModal
            show={isCoursesModalOpen}
            toggleModal={toggleCourseModal}
            currentOrder={currentOrder}
            seatsData={seatsData}
            menuItemsWithCourseStatus={menuItemsWithCourseStatus}
            setMenuItemsWithCourseStatus={setMenuItemsWithCourseStatus}
          />
        )}
      </DragDropContext>
    </>
  );
};
