import React, { useEffect, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PayMaster from 'assets/img/pay_master.svg';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';
import RefundContext from '../../context/RefundContext';
import { PosIcon } from '../../assets/icons/PosIcon';
import { useOrdersGlobalState } from '../../pages/Orders/components/Providers/OrdersGlobalState/OrdersGlobalState';
import { useApi } from '../../hooks/useApi';
import { nextOrderConfigURLSelector } from '../../pages/Dashboard/selectors';
import { PosIconLoading } from 'assets/icons/PosIconLoading';
import { fetchCustomerNameByPhoneNumber } from 'services/homeServices';
import { addUserWithMobileNumber } from 'services/customerServices';
import { isObjectNotEmpty } from 'util/utils';

const capitalize = (s) => `${s.substr(0, 1).toUpperCase()}${s.substr(1)}`;

export const RefundDetailsModal = ({ showModal, onModalClose, refundType }) => {
  const {
    keyboardVisibility,
    toggleKeyboard,
    setInputName,
    getInputValue,
    onInitInput,
    onChangeInput,
    inputNames,
  } = useVirtualKeyboardContext();
  const inputName = inputNames.refundMessage;
  const inputRef = useRef(null);

  const [state, setState] = useContext(RefundContext);
  const [newUserId, setNewUserId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onInitInput(inputName, state.message);
  }, [state.message, inputName]);

  const storeConfig = useSelector(
    (state) => state.dashboardReducer.storeConfig
  );
  const refundReasons = useSelector(
    (state) => state.dashboardReducer.refundReasons
  );

  useEffect(async () => {
    if (isObjectNotEmpty(state)) {
      if (state.phone) {
        const fetchUserDetail = await fetchCustomerNameByPhoneNumber({
          restaurantId: storeConfig.restaurantId,
          mobileNumber: state.phone,
        });

        if (fetchUserDetail.user === null) {
          const nameDetail = state.name.trim().split(' ');
          const firstName = nameDetail[0];
          const lastName = nameDetail[1] || '';

          const addUserApiResponse = await addUserWithMobileNumber({
            restaurantId: storeConfig.restaurantId,
            timeZone: storeConfig.timeZone,
            mobileNumber: state.phone,
            firstName: firstName,
            lastName: lastName,
          });

          if (addUserApiResponse.userId) {
            setNewUserId(addUserApiResponse.userId);
          }
        } else if (isObjectNotEmpty(fetchUserDetail.user)) {
          setNewUserId(fetchUserDetail.user._id);
          setState({
            name: `${fetchUserDetail.user.firstName} ${fetchUserDetail.user.lastName}`,
          });
        }
      }
    }
  }, [state.phone]);

  const { nextOrderConfigURL } = useSelector(nextOrderConfigURLSelector);

  const [refundResponse, requestRefund] = useApi(
    'POST',
    `${nextOrderConfigURL}/refund`
  );
  const [voucherResponse, requestVoucher] = useApi(
    'POST',
    `${nextOrderConfigURL}/createUserVoucher`
  );

  const { selectedOrder } = useOrdersGlobalState();

  const onConfirm = async () => {
    setLoading(true);
    if (refundType === 'voucher') {
      requestVoucher(
        {
          storeId: storeConfig?.storeId,
          userId:
            selectedOrder.userId === 'xxx-xxx-xxx'
              ? newUserId
              : selectedOrder.userId,
          message: state.message,
          timeZone: storeConfig.timeZone,
          orderId: selectedOrder._id,
          amount: parseFloat(state.refundAmount?.toFixed(2)),
          reasonId: state.reasonId,
        },
        { mode: 'no-cors' }
      );
    } else {
      requestRefund(
        {
          storeId: storeConfig?.storeId,
          timeZone: storeConfig.timeZone,
          orderId: selectedOrder._id,
          amount: parseFloat(state.refundAmount?.toFixed(2)),
          reasonId: state.reasonId,
        },
        { mode: 'no-cors' }
      );
    }
  };

  useEffect(() => {
    if (refundResponse.data?.success) {
      setState({ step: 'issued' });
    } else if (refundResponse.error) {
      setState({ step: 'error' });
    }
  }, [refundResponse]);

  useEffect(() => {
    if (voucherResponse.data?.success) {
      setState({ step: 'issued' });
    } else if (voucherResponse.error) {
      setState({ step: 'error' });
    }
  }, [voucherResponse]);

  useEffect(() => {
    if (selectedOrder) {
      const { firstName, lastName, mobileNumber: phone } = selectedOrder;
      const name = `${firstName} ${lastName}`;
      setState({ name, phone });
    }
  }, [selectedOrder]);

  const reason = refundReasons.find(({ _id }) => _id === state.reasonId);

  useEffect(() => {
    if (reason) {
      let refundMessage =
        refundType === 'voucher'
          ? reason?.voucherMsg
          : parseFloat(state.refundAmount) <
            parseFloat(selectedOrder?.payableAmount)
          ? reason?.refundMsgPartial
          : reason?.refundMsg;

      if (refundMessage) {
        refundMessage = refundMessage.replace(
          '##Currency##',
          storeConfig.currency
        );
        refundMessage = refundMessage.replace('##VoucherCode##', 'XXXXXX');
        if (state.refundAmount.toFixed) {
          refundMessage = refundMessage.replace(
            '##Amount##',
            state.refundAmount.toFixed(2)
          );
        }
        refundMessage = refundMessage.replace('##Store##', storeConfig.name);

        setState({ message: refundMessage });
      }
    }
  }, [reason, refundType, selectedOrder.payableAmount, state.refundAmount]);

  return (
    <>
      {showModal && /(details|error|issued)/.test(state.step) && (
        <div className="general-modal__inner">
          <div className="general-modal__header general-modal__header--shadow ">
            <div className="general-modal__title-box">
              <h3 className="general-modal__title general-modal__title--size-small">
                Confirm {capitalize(refundType)} Details
              </h3>
            </div>
            <div className="general-modal__desc general-modal__desc--size-small">
              <p>
                Please check the below information is correct <br /> before
                {refundType === 'voucher' ? ' issu' : ' process'}ing the{' '}
                {refundType}.
              </p>
            </div>
          </div>

          <div className="general-modal__body">
            <div className="general-modal__body-inner">
              <div className="general-modal__form-box">
                <div className="general-modal__input-box">
                  <input
                    className="general-modal__bem-input bem-input"
                    readOnly
                    value={state?.name || ''}
                    name="name"
                    type="text"
                  />
                  <PosIcon
                    className="pos-icon--input-icon"
                    name="man-circle"
                    size="custom"
                    customHeight={24}
                    customWith={24}
                    mainColor="#c4c4c4"
                    darkModeColor="#c4c4c4"
                  />
                </div>
                <div className="general-modal__input-box">
                  <input
                    className="general-modal__bem-input bem-input"
                    value={state?.phone || ''}
                    onFocus={() => setState({ showPhoneModal: true })}
                    name="phone"
                    type="text"
                  />
                  <PosIcon
                    className="pos-icon--input-icon"
                    name="phone-second"
                    size="custom"
                    customHeight={24}
                    customWith={24}
                    mainColor="#c4c4c4"
                    darkModeColor="#c4c4c4"
                  />
                </div>

                <div className="general-modal__row">
                  <h4 className="general-modal__row-title">SMS Message</h4>
                  <button
                    className="button button--customize"
                    type="button"
                    title="Customize"
                    onClick={() => {
                      if (!keyboardVisibility) {
                        setInputName(inputName);

                        toggleKeyboard(true);
                        setTimeout(() => inputRef.current.focus(), 1);

                        setTimeout(
                          () =>
                            inputRef.current.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.scrollTo(
                              0,
                              inputRef.current.clientTop +
                                inputRef.current.clientHeight +
                                40
                            ),
                          2
                        );
                      } else {
                        toggleKeyboard(false);
                        inputRef.current.blur();
                      }
                    }}
                  >
                    <PosIcon
                      name="note"
                      size="custom"
                      customWith={16}
                      customHeight={18}
                      currentColor
                    />
                    {keyboardVisibility ? 'Save' : 'Customize'}
                  </button>
                </div>

                <div className="general-modal__message-box">
                  <textarea
                    style={{
                      height: '100%',
                      width: '100%',
                      border: 'none',
                      font: '400 1.8rem/28px "HelveticaNowText", sans-serif',
                      fontSize: 16,
                    }}
                    ref={inputRef}
                    disabled={!keyboardVisibility}
                    value={getInputValue(inputName)}
                    onFocus={(e) => e.preventDefault()}
                    onBlur={() => toggleKeyboard(false)}
                    onChange={(e) => {
                      e.preventDefault();
                      setState({ message: e.target.value });
                      onChangeInput(inputName, e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="general-modal__footer general-modal__footer--shadow">
            <div className="general-modal__pay-row">
              <div className="general-modal__pay-img-box">
                {refundType !== 'voucher' && (
                  <div className="paycard-box master">
                    <img src={PayMaster} alt="Master Card" />
                  </div>
                )}
              </div>
              <div className="general-modal__price-box">
                <div className="general-modal__price-title">
                  {capitalize(refundType)} Total
                </div>
                <div className="general-modal__price">
                  ${state.refundAmount?.toFixed(2)}
                </div>
              </div>
            </div>
            <button
              onClick={onConfirm}
              disabled={!state.phone || state.phone === '' || loading}
              style={{ ...(loading ? { background: '#ccc' } : {}) }}
              className="button button--rounded-none button--align-center button--size-biggest button--fullwidth button--theme-green"
              title="Process Refund"
              type="button"
            >
              {refundType === 'voucher' ? 'Issue' : 'Process'}{' '}
              {capitalize(refundType)}
              {loading && (
                <>
                  &nbsp;&nbsp;
                  <PosIconLoading
                    mainColor="white"
                    style={{
                      transform: 'translateY(3px)',
                      width: '18px',
                      height: '18px',
                    }}
                  />
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default RefundDetailsModal;
