import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useModal from 'hooks/useModal';

function CustomerNameEffect() {
  const currentOrder = useSelector((s) => s.dashboardReducer.currentOrder);
  const [nameModalOpen, { openModal, closeModal }] =
    useModal('customerNameModal');
  const [phoneModalOpen] = useModal('customerPhoneModal');

  useEffect(() => {
    if (!currentOrder?._id) {
      if (
        (!currentOrder?.firstName || currentOrder?.firstName === '') &&
        !nameModalOpen &&
        !phoneModalOpen
      ) {
        openModal();
      } else if (currentOrder?.firstName && (nameModalOpen || phoneModalOpen)) {
        closeModal();
      }
    }
  }, [
    nameModalOpen,
    currentOrder?._id,
    currentOrder?.firstName,
    openModal,
    closeModal,
    phoneModalOpen,
    currentOrder?.isCustomerNameFetched,
  ]);

  return null;
}

export default CustomerNameEffect;
