import VirtualList from 'components/VirtualList';
import * as JsSearch from 'js-search';
import { getQueryForOrderDateFilter } from 'pages/Orders/components/Providers/OrderList/utils';
import { setOrders } from 'pages/Orders/newOrdersReducer';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useDispatch, useSelector } from 'react-redux';
import normalize from 'util/normalize';
import { OrdersSideBardCardRepeatShimmer } from '../../ShimmerLoaders/OrderSideBar';
import GuestOrder from '../sidebar/GuestOrder';
import { orderTimeSortOrderFilterSelector } from '../../../pages/Orders/selectors';
import { setIsOrdersLoading } from '../../../pages/Dashboard/action';
import {
  getTimestampForDateStart,
  getTimestampForDateEnd,
} from 'util/timeHelper';
import dayjs from 'dayjs';
import { orderScreenOrderTypesMap } from 'configuration/enums';
import moment from 'moment-timezone';

const cap = (str) => str.substr(0, 1).toUpperCase() + str.substr(1);

const useOrdersData = () => {
  const { orders, filters, ...state } = useSelector(
    (state) => state.newOrdersReducer
  );
  const { storeId } = useSelector(
    (state) => state.dashboardReducer.storeConfig
  );

  const dispatch = useDispatch();

  const { date, type } = filters;
  const SOD = dayjs().startOf('day').valueOf();
  const EOD = new Date().setUTCHours(23, 59, 59, 999);

  const currentQuery = getQueryForOrderDateFilter(
    date,
    'all',
    storeId,
    SOD,
    EOD
  );

  const [collection, loading, error] = useCollection(currentQuery);

  const { allOrdersMap, allOrdersList } = orders;

  useEffect(() => {
    dispatch(setIsOrdersLoading(loading));
  }, [loading]);

  useEffect(() => {
    let orderList = [];

    if (collection?.docs && !loading) {
      const dataChanged = collection
        .docChanges()
        .map((change) => ({ _id: change.doc.id, ...change.doc.data() }))
        .filter((order) => order.orderStatus !== '-1');

      const { incomingList, incomingMap } = normalize(dataChanged, 'incoming');

      let allOrdersData =
        Object.values({ ...allOrdersMap, ...incomingMap }) || [];
      if (date === 'current') {
        allOrdersData = allOrdersData.filter(
          (order) =>
            order?.deliveryDateTimestamp >= SOD &&
            order?.deliveryDateTimestamp < EOD &&
            order.orderStatus !== '11'
        );
      } else if (date === 'future') {
        allOrdersData = allOrdersData.filter(
          (order) =>
            order?.deliveryDateTimestamp > EOD &&
            order?.deliveryDateTimestamp <
              getTimestampForDateEnd(moment().add(7, 'days')) &&
            order.orderStatus !== '11'
        );
      } else if (date === 'history') {
        allOrdersData = allOrdersData.filter(
          (order) =>
            order?.deliveryDateTimestamp < SOD &&
            order?.deliveryDateTimestamp >=
              getTimestampForDateStart(moment().add(-7, 'days')) &&
            order.orderStatus !== '11'
        );
      }
      // Filter Data if type is not all
      if (type !== 'all') {
        const filteredOrders = allOrdersData.filter(
          (order) => order.orderType === orderScreenOrderTypesMap[type]
        );

        const filteredOrdersIds = filteredOrders.map(({ _id }) => _id);
        orderList = filteredOrdersIds;
      } else {
        const alldOrdersIds = allOrdersData.map(({ _id }) => _id);

        orderList = alldOrdersIds;
      }

      dispatch(
        setOrders({
          allOrdersList: Array.from(
            new Set([...allOrdersList, ...incomingList])
          ),
          allOrdersMap: {
            ...allOrdersMap,
            ...incomingMap,
          },
          [`${date}${cap(type)}List`]: orderList,
        })
      );
    }
  }, [collection, dispatch, loading, type, SOD, EOD]);

  const currentOrdersList = useMemo(() => {
    return orders[`${date}${cap(type)}List`] || [];
  }, [date, type, orders]);

  return [currentOrdersList, { loading, error, dispatch, filters, ...state }];
};

var s = new JsSearch.Search('_id');
s.addIndex('firstName');
s.addIndex('lastName');
s.addIndex('mobileNumber');
s.addIndex('tableNumber');
s.addIndex('deliveryCode');

const sortingOrderFilterMap = {
  placedTime: 'dateTimestamp',
  expectedTime: 'deliveryDateTimestamp',
};

export const ReadySidebar = () => {
  useOrdersData();

  const {
    filters: { search, allAndUnpaid, type, date },
    selectedOrderId,
    orders,
  } = useSelector((state) => state.newOrdersReducer);

  const isOrdersLoading = useSelector(
    (state) => state.dashboardReducer.isOrdersLoading
  );

  const ordersList = useMemo(() => {
    return orders[`${date}${cap(type)}List`] || [];
  }, [date, type, orders]);

  const dispatch = useDispatch();
  const ordersMap = useSelector(
    (state) => state.newOrdersReducer.orders.allOrdersMap
  );
  const orderTimeSortOrderFilter = useSelector(
    orderTimeSortOrderFilterSelector
  );

  const [searchList, setSearchList] = useState();

  const searchRef = useRef(s);

  const renderListItem = useCallback(
    ({ itemId }) => <GuestOrder itemId={itemId} />,
    []
  );

  useEffect(() => {
    return () => {
      searchRef.current = null;
    };
  }, [dispatch]);

  useEffect(() => {
    let searchList = ordersList;

    if (searchRef?.current?._documents && search !== '') {
      searchList = searchRef.current.search(search);

      setSearchList(searchList.map(({ _id }) => _id));
    } else if (search === '' && allAndUnpaid === 'all') {
      const orderListDetail = ordersList.map((id) => {
        return ordersMap[id];
      });
      const sortedOrderListDetail = orderListDetail.sort(
        (a, b) =>
          b[sortingOrderFilterMap[orderTimeSortOrderFilter]] -
          a[sortingOrderFilterMap[orderTimeSortOrderFilter]]
      );

      const sortedOrderListIds = sortedOrderListDetail.map(({ _id }) => _id);
      setSearchList(sortedOrderListIds);
    }
  }, [searchRef?.current?._documents, search, ordersList, allAndUnpaid]);

  useEffect(() => {
    if (
      allAndUnpaid === 'unpaid' &&
      ordersList?.length &&
      ordersMap &&
      search === ''
    ) {
      setSearchList(
        (ordersList || [])
          .map((id) => ordersMap[id])
          .filter(
            ({ paymentStatus }) => paymentStatus === 0 || paymentStatus === 2
          )
          .map(({ _id }) => _id)
      );
    } else if (searchList?.length && ordersMap && search === '') {
      const orderListDetail = ordersList.map((id) => {
        return ordersMap[id];
      });
      const sortedOrderListDetail = orderListDetail.sort(
        (a, b) =>
          b[sortingOrderFilterMap[orderTimeSortOrderFilter]] -
          a[sortingOrderFilterMap[orderTimeSortOrderFilter]]
      );

      const sortedOrderListIds = sortedOrderListDetail.map(({ _id }) => _id);
      setSearchList(sortedOrderListIds);
    }
    // eslint-disable-next-line
  }, [allAndUnpaid, ordersMap, ordersList, search, JSON.stringify(searchList)]);

  useEffect(() => {
    if (
      ordersList.length &&
      ordersList.length !== searchRef.current?._documents?.length &&
      search !== '' &&
      ordersMap
    ) {
      searchRef?.current?.addDocuments(ordersList.map((id) => ordersMap[id]));
    }
  }, [ordersList, ordersMap, search, allAndUnpaid]);

  // if (loading && !ordersList.length) {
  //   return <OrdersSideBardCardRepeatShimmer repeat={4} />;
  // }

  return (
    <>
      {isOrdersLoading && !ordersList.length ? (
        <OrdersSideBardCardRepeatShimmer repeat={6} />
      ) : (
        <VirtualList
          data={searchList || ordersList}
          selectedItemId={selectedOrderId}
          renderListItem={renderListItem}
        />
      )}
    </>
  );
};
