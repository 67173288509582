import { isMacOs, isWindows } from 'react-device-detect';
import ActionTypes from './constants';
import initialState from './initState';

export const _getActiveObjects = (items) => {
  return items?.filter((cat) => cat.isActive) || [];
};

export const _getDeActiveObjects = (items) => {
  return items?.filter((cat) => !cat.isActive) || [];
};

const _getSpecialsFilteredMenuItems = (
  originalMenuItems,
  activeSizes,
  activeSubModifiers
) => {
  return (
    originalMenuItems
      ?.map((item) => {
        // do not include items, whose selectedSizes length is zero
        const selectedSizes = item.selectedSizes
          ?.filter((size) => {
            if (item.itemType === 0) {
              return activeSizes.some((ms) => ms._id === size._id);
            } else {
              return activeSubModifiers.some((asm) => asm._id === size._id);
            }
          })
          .map((size) => ({
            ...size,
            variants: size.variants?.filter((variant) => variant.isSelected),
          }));
        if (selectedSizes.length) {
          const selectedModifiers = item.selectedModifiers
            ?.filter((sm) => sm._id)
            .map((modifier) => {
              if (modifier._id === item.baseModifierId) {
                return {
                  ...modifier,
                  subModifiers: modifier.subModifiers
                    ?.filter((subMod) => {
                      return activeSubModifiers.some(
                        (sm) => sm._id === subMod._id
                      );
                    })
                    .map((subMod) => ({
                      ...subMod,
                      variants: subMod.variants?.filter(
                        (variant) => variant.isSelected
                      ),
                    })),
                };
              }
              return {
                ...modifier,
                subModifiers: modifier.subModifiers?.map((sm) => ({
                  ...sm,
                  prices: sm.prices?.filter((price) =>
                    activeSubModifiers?.some((sm) => sm._id === price._id)
                  ),
                })),
              };
            })
            .filter((m) => m && m.subModifiers?.length);
          return {
            ...item,
            selectedSizes,
            selectedModifiers,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item && item.selectedSizes?.length)
      .sort((a, b) => {
        return a.name
          ?.toString()
          .localeCompare(b.name?.toString(), 'en', { numeric: true });
      }) || []
  );
};

export const _getHalfHalfFilteredMenuItems = (
  originalMenuItems,
  activeSizes,
  activeSubModifiers,
  variantSelected
) => {
  return (
    originalMenuItems
      ?.map((item) => {
        // do not include items, whose selectedSizes length is zero
        const selectedSizes = item.selectedSizes
          ?.filter((size) => {
            if (item.isPublished && item.itemType === 0) {
              return activeSizes.some((ms) => ms._id === size._id);
            }
            return (
              item.isPublished &&
              activeSubModifiers.some((asm) => asm._id === size._id)
            );
          })
          .map((size) => {
            return {
              ...size,
              variants: size.variants?.filter((variant) => variant.isSelected),
            };
          })
          .filter((size) => {
            if (variantSelected) {
              const { variants } = size;
              return variants.some(
                (variant) => variant._id === variantSelected
              );
            } else {
              return true;
            }
          });
        if (selectedSizes.length) {
          const selectedModifiers = item.selectedModifiers
            ?.filter((sm) => sm._id)
            .map((modifier) => {
              if (modifier._id === item.baseModifierId) {
                return {
                  ...modifier,
                  subModifiers: modifier.subModifiers
                    ?.filter((subMod) => {
                      return activeSubModifiers.some(
                        (sm) => sm._id === subMod._id
                      );
                    })
                    .map((subMod) => ({
                      ...subMod,
                      variants: subMod.variants?.filter(
                        (variant) => variant.isSelected
                      ),
                    })),
                };
              }
              return {
                ...modifier,
                subModifiers: modifier.subModifiers?.map((sm) => ({
                  ...sm,
                  prices: sm.prices?.filter((price) =>
                    activeSubModifiers?.some((sm) => sm._id === price._id)
                  ),
                })),
              };
            })
            .filter((m) => m && m.subModifiers?.length);
          return {
            ...item,
            selectedSizes,
            selectedModifiers,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item && item.selectedSizes?.length)
      .sort((a, b) => {
        return a.name
          ?.toString()
          .localeCompare(b.name?.toString(), 'en', { numeric: true });
      }) || []
  );
};

const _generateCurrentSpecialsItem = (specialsItem, menuItems) => {
  const selectedCategories = [];
  specialsItem.selectedCategories?.forEach((cat) => {
    let i = 0;
    while (i < cat.quantity) {
      selectedCategories.push({ ...cat, quantity: 1 });
      i++;
    }
  });
  return {
    ...specialsItem,
    selectedCategories: selectedCategories
      .sort((a, b) => a?.index - b?.index)
      .map((selCat, index) => {
        const categories = _getActiveObjects(selCat.categories);
        const menuSizes = _getActiveObjects(selCat.menuSizes);
        const subModifiers = _getActiveObjects(selCat.subModifiers);
        const activeMenuItems = _getActiveObjects(selCat.menuItems);
        const originalMenuItems = menuItems
          ?.filter(
            (item) =>
              activeMenuItems.some((mi) => mi._id === item._id) &&
              categories?.some((cat) => cat._id === item.categoryId) &&
              item.isAvailable &&
              item.isPublished
          )
          .map((item) => {
            // get the upsalePrice for each item from the specials' menuItems
            const itemFromSpecials = activeMenuItems.find(
              (ami) => ami._id === item._id
            );
            if (itemFromSpecials) {
              return {
                ...item,
                upsalePrice: itemFromSpecials.price
                  ? Number(itemFromSpecials.price)
                  : 0,
              };
            }
            return item;
          });
        const finalMenuItems = _getSpecialsFilteredMenuItems(
          originalMenuItems,
          menuSizes,
          subModifiers
        );
        return {
          ...selCat,
          index,
          categories,
          menuSizes,
          subModifiers,
          menuItems: finalMenuItems,
        };
      }),
  };
};

/**
  originalHalfHalf -> from product setup,
  state.menuItems -> all menu items,
  originalHalfHalf.selectedSizes -> selectedSizes,
  defaultSize -> active size object
 * @returns
 */
export const _generateCurrentHalfHalfData = (
  halfHalf,
  menuItems,
  selectedSizes,
  defaultSize,
  variantSelected = ''
) => {
  const categories = _getActiveObjects(halfHalf.categories);
  const activeSelectedSizes = _getActiveObjects(selectedSizes);
  const activeSubModifiers = _getActiveObjects(halfHalf.selectedModifiers); // these are actually base modifier's sub modifiers

  const defaultSizeWithPairId = {
    ...defaultSize,
    ...halfHalf.selectedSizes?.find((d) => d._id === defaultSize._id),
  };
  const activeSelectedSizesWithPairId = activeSelectedSizes?.map((size) => {
    const sizeInitialData = halfHalf.selectedSizes?.find(
      (s) => s._id === size._id
    );
    return {
      ...size,
      ...sizeInitialData,
    };
  });

  const activeModifiersWithPairId = activeSubModifiers?.map((modifier) => {
    const modiferInitialData = halfHalf.selectedModifiers?.find(
      (m) => m._id === modifier._id
    );

    return {
      ...modifier,
      ...modiferInitialData,
    };
  });

  const matchingSize = activeSelectedSizesWithPairId.find(
    (f) => f._id === defaultSizeWithPairId._id
  );
  const matchingSubModifiers = activeModifiersWithPairId.find(
    (f) => f._id === defaultSizeWithPairId._id
  );

  const pairId =
    matchingSubModifiers?.pairId ??
    matchingSize?.pairId ??
    defaultSize?.pairId ??
    '0';

  let selectedSizesWithDefaultSelected =
    activeSelectedSizesWithPairId.map((size) => {
      if (
        size._id === defaultSize._id ||
        size.name.toLowerCase() === defaultSize.name.toLowerCase() ||
        size.pairId === pairId
      ) {
        return {
          ...size,
          isDefault: true,
        };
      }
      return {
        ...size,
        isDefault: false,
      };
    }) || [];
  let subModifiersWithDefaultSelected =
    activeModifiersWithPairId.map((size) => {
      if (
        // size._id === defaultSize._id ||
        size.name.toLowerCase() === defaultSize.name.toLowerCase() ||
        size.pairId === pairId
      ) {
        return {
          ...size,
          isDefault: true,
        };
      }
      return {
        ...size,
        isDefault: false,
      };
    }) || [];

  const finalMenuItems = _getHalfHalfFilteredMenuItems(
    // START TEMP FIX ------------------------------------------
    // originalMenuItems,
    menuItems,
    // END TEMP FIX --------------------------------------------
    selectedSizesWithDefaultSelected.filter((size) => size.isDefault),
    subModifiersWithDefaultSelected.filter((sm) => sm.isDefault),
    variantSelected
  );
  const filteredMenuItems = [];
  categories.map((category) => {
    finalMenuItems.map((menuItem) => {
      if (menuItem.categoryId === category._id) {
        filteredMenuItems.push(menuItem);
      }
    });
  });

  const posMenuItems = filteredMenuItems?.filter((item) =>
    item?.orderFromKeys?.includes('2')
  );

  return {
    ...halfHalf,
    categories,
    subModifiers: subModifiersWithDefaultSelected,
    selectedSizes: selectedSizesWithDefaultSelected,
    menuItems: posMenuItems,
  };
};

const _generateCurrentFourQuarterData = (
  fourQuarter,
  menuItems,
  selectedSizes,
  defaultSize,
  variantSelected = ''
) => {
  const categories = _getActiveObjects(fourQuarter.categories);
  const activeSelectedSizes = _getActiveObjects(selectedSizes);
  const activeSubModifiers = _getActiveObjects(fourQuarter.selectedModifiers); // these are actually base modifier's sub modifiers
  const deActiveMenuItems = _getDeActiveObjects(fourQuarter.menuItems);
  const originalMenuItems = menuItems?.filter(
    (item) => !deActiveMenuItems.some((mi) => mi._id === item._id)
  );
  let selectedSizesWithDefaultSelected =
    activeSelectedSizes.map((size) => {
      if (
        size._id === defaultSize._id ||
        size.name.toLowerCase() === defaultSize.name.toLowerCase()
      ) {
        return {
          ...size,
          isDefault: true,
        };
      }
      return {
        ...size,
        isDefault: false,
      };
    }) || [];
  let subModifiersWithDefaultSelected =
    activeSubModifiers.map((size) => {
      if (
        size._id === defaultSize._id ||
        size.name.toLowerCase() === defaultSize.name.toLowerCase()
      ) {
        return {
          ...size,
          isDefault: true,
        };
      }
      return {
        ...size,
        isDefault: false,
      };
    }) || [];
  //consider selectedModifiers as the size
  // selectedSizesWithDefaultSelected = [...selectedSizesWithDefaultSelected, ...subModifiersWithDefaultSelected]
  const finalMenuItems = _getHalfHalfFilteredMenuItems(
    originalMenuItems,
    selectedSizesWithDefaultSelected.filter((size) => size.isDefault),
    subModifiersWithDefaultSelected.filter((sm) => sm.isDefault),
    variantSelected
  );
  const posMenuItems = finalMenuItems?.filter((item) =>
    item?.orderFromKeys?.includes('2')
  );
  return {
    ...fourQuarter,
    // category: categories.find(cat=>cat._id===activeCategoryId),
    categories,
    subModifiers: subModifiersWithDefaultSelected,
    selectedSizes: selectedSizesWithDefaultSelected,
    menuItems: posMenuItems,
  };
};

const dashboardReducer = (state = initialState, action) => {
  const { type, ...payload } = action;

  switch (type) {
    case ActionTypes.Initialize:
      return {
        ...state,
        ...payload,
      };

    case ActionTypes.UPDATE_STORE_CONFIG:
      return {
        ...state,
        storeConfig: action.payload.storeConfig,
        allStores: action.payload.allStores,
      };

    case ActionTypes.SET_STRIPE_CARD_PAYMENTS:
      return {
        ...state,
        stripeCardPayments: action.payload.stripeCardPayments,
      };

    case ActionTypes.AppLoaded:
      return {
        ...state,
        appLoaded: true,
        fullScreen: true,
        virtualKeyboard:
          localStorage.getItem('virtualKeyboard') === 'true' &&
          (isMacOs || isWindows),
      };
    case ActionTypes.ToggleVirtualKeyboard:
      return {
        ...state,
        virtualKeyboard: !state.virtualKeyboard,
      };
    case ActionTypes.ToggleFullScreen:
      return {
        ...state,
        fullScreen: !state.fullScreen,
      };
    case ActionTypes.ToggleBlurEffect:
      return {
        ...state,
        blurEffect: payload.blurEffect,
      };
    case ActionTypes.ToggleSingleOption:
      return {
        ...state,
        [payload.payload]: !state[payload.payload],
      };
    case ActionTypes.OpenMenuItem:
      return {
        ...state,
        currentMenuItem: {
          ...payload.menuItem,
          isCurrentMenuItemInEditMode: payload.isCurrentMenuItemInEditMode,
        },
      };
    case ActionTypes.ToggleItemLargePhotoView:
      return {
        ...state,
        isItemLargePhotoView: !state.isItemLargePhotoView,
      };
    case ActionTypes.UpdateMenuItem:
      return {
        ...state,
        currentMenuItem: payload.menuItem,
      };
    case ActionTypes.ResetMenuItem:
      return {
        ...state,
        currentMenuItem: {},
      };
    case ActionTypes.UpdateCurrentOrder:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          ...payload.currentOrder,
        },
      };
    case ActionTypes.ResetCurrentOrder:
      return {
        ...state,
        currentOrder: initialState.currentOrder,
        currentMenuItem: {},
        title: '',
      };
    case ActionTypes.UpdateCurrentOrderDeliveryLocation:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          ...payload.location,
        },
      };
    case ActionTypes.ResetCurrentOrderDeliveryLocation:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          name: '',
          unit: '',
          notes: '',
          address: '',
          addressLocation: {},
        },
      };
    case ActionTypes.SetActionsModalConfig:
      return {
        ...state,
        actionsModalConfig: payload.config,
      };
    case ActionTypes.ResetActionsModalConfig:
      return {
        ...state,
        actionsModalConfig: initialState.actionsModalConfig,
      };
    case ActionTypes.SetItemActionsModalConfig:
      return {
        ...state,
        itemActionsModalConfig: payload.config,
      };
    case ActionTypes.ResetItemActionsModalConfig:
      return {
        ...state,
        itemActionsModalConfig: initialState.itemActionsModalConfig,
      };
    case ActionTypes.UpdateCurrentSpecialsItem:
      return {
        ...state,
        currentSpecialsItem: _generateCurrentSpecialsItem(
          payload.specialsItem,
          state.menuItems
        ),
      };
    case ActionTypes.ResetCurrentSpecialsItem:
      return {
        ...state,
        currentSpecialsItem: {},
      };
    case ActionTypes.ToggleSpecialsCategoryEditMode:
      return {
        ...state,
        currentSpecialsItem: {
          ...state.currentSpecialsItem,
          selectedCategories: state.currentSpecialsItem.selectedCategories.map(
            (cat) => {
              if (cat.index === payload.categoryIndex) {
                return {
                  ...cat,
                  isInEditMode: true,
                };
              }
              return {
                ...cat,
                isInEditMode: false,
              };
            }
          ),
        },
      };
    case ActionTypes.UpdateCurrentSpecialsCategoryItem:
      return {
        ...state,
        currentSpecialsItem: {
          ...state.currentSpecialsItem,
          selectedCategories: state.currentSpecialsItem.selectedCategories.map(
            (cat) => {
              if (cat.index === payload.categoryIndex) {
                return {
                  ...cat,
                  currentMenuItems: Array.isArray(payload.menuItem)
                    ? payload.menuItem
                    : [payload.menuItem],
                  isInEditMode: false,
                };
              }
              return {
                ...cat,
              };
            }
          ),
        },
      };
    case ActionTypes.SetSingleHalfFlow:
      return {
        ...state,
        isSingleHalfFlow: payload.isSingleHalfFlow,
      };

    case ActionTypes.SET_INCOMING_CALLS_NOTIFICATIONS:
      return {
        ...state,
        incomingCallsNotifications: payload.incomingCallsNotifications,
      };

    case ActionTypes.SET_ACCEPTED_CALL:
      return {
        ...state,
        acceptedCall: payload.acceptedCall,
      };

    case ActionTypes.SET_ACTIVE_CATEGORY_INDEX:
      return {
        ...state,
        activeCategoryIndex: payload.activeCategoryIndex,
      };

    case ActionTypes.SetShowPizzaActionButton:
      return {
        ...state,
        showPizzaActionButton: payload.showPizzaActionButton,
      };
    case ActionTypes.SET_HALF_HALF_MENU_ITEMS:
      let originalHalf = {};
      if (
        state?.productSetup?.halfHalfs &&
        state?.productSetup?.halfHalfs.length > 0
      ) {
        state.productSetup.halfHalfs.map((item) => {
          item.categories.map((category) => {
            if (category._id === payload.categoryId) {
              originalHalf = item;
            }
          });
        });
      }

      const initialSize = originalHalf.defaultSizes?.find(
        (size) => size.isActive
      );
      const halfHalfObject = _generateCurrentHalfHalfData(
        originalHalf,
        state.menuItems,
        originalHalf.selectedSizes,
        initialSize
      );
      return {
        ...state,
        halfHalfMenuItems: halfHalfObject.menuItems || [],
      };

    case ActionTypes.UpdateCurrentHalfHalfData:
      let originalHalfHalf = {};
      if (
        state?.productSetup?.halfHalfs &&
        state?.productSetup?.halfHalfs.length > 0
      ) {
        state.productSetup.halfHalfs.map((item) => {
          item.categories.map((category) => {
            if (category._id === payload.categoryId) {
              originalHalfHalf = item;
            }
          });
        });
      }

      const defaultSize = originalHalfHalf.defaultSizes?.find(
        (size) => size.isActive
      );
      const halfHalf = _generateCurrentHalfHalfData(
        originalHalfHalf,
        state.menuItems,
        originalHalfHalf.selectedSizes,
        defaultSize
      );
      return {
        ...state,
        currentHalfHalfData: {
          ...state.currentHalfHalfData,
          activeCategoryId: payload.categoryId,
          halfHalfs: [
            { halfIndex: 1, ...halfHalf },
            { halfIndex: 2, ...halfHalf },
          ],
          variantSelected: '',
        },
      };

    case ActionTypes.SetHalfHalfForModify:
      const originalHalfHalfForModify = state.productSetup.halfHalfs
        ? state.productSetup.halfHalfs[0]
        : {};
      const { categoryId } = payload.menuItem.items[0];
      const halfHalfForModify = _generateCurrentHalfHalfData(
        originalHalfHalfForModify,
        state.menuItems,
        originalHalfHalfForModify.selectedSizes,
        payload.size,
        payload.variantSelected
      );
      return {
        ...state,
        currentHalfHalfData: {
          ...state.currentHalfHalfData,
          activeCategoryId: categoryId,
          halfHalfs: [
            {
              halfIndex: 1,
              ...halfHalfForModify,
              currentMenuItem: payload.menuItem.items[0],
            },
            {
              halfIndex: 2,
              ...halfHalfForModify,
              currentMenuItem: payload.menuItem.items[1],
            },
          ],
          isInModifyMode: true,
          halfIndexForModify: payload.halfIndex,
          variantSelected: payload.variantSelected,
        },
      };
    case ActionTypes.ResetCurrentHalfHalfData:
      return {
        ...state,
        currentHalfHalfData: {},
      };
    case ActionTypes.TOGGLE_HALF_SELECTED_SIZE:
      return {
        ...state,
        currentHalfHalfData: {
          ...state.currentHalfHalfData,
          halfHalfs: [
            { halfIndex: 1, ...action.payload.firstHalfData },
            { halfIndex: 2, ...action.payload.secondHalfData },
          ],
          variantSelected:
            action.payload.variantSelected ||
            /**
             * @transit
             * did this to help save an error if there is any call
             * happening without `action.payload.variantSelected`
             * should be removed at last
             */
            state.currentHalfHalfData?.variantSelected,
        },
      };
    case ActionTypes.ToggleHalfHalfEditMode:
      return {
        ...state,
        currentHalfHalfData: {
          ...state.currentHalfHalfData,
          halfHalfs: state.currentHalfHalfData.halfHalfs.map((cat) => {
            if (cat.halfIndex === payload.halfIndex) {
              return {
                ...cat,
                isInEditMode: true,
              };
            }
            return {
              ...cat,
              isInEditMode: false,
            };
          }),
        },
      };

    case ActionTypes.TOGGLE_HALF_HALF_SELECTED_VARIANT:
      return toggleHalfHalfSelectedVariant(state, action);

    case ActionTypes.UpdateCurrentHalfHalfItem:
      return updateCurrentHalfAndHalfItem(state, payload);

    case ActionTypes.UpdateCurrentFourQuarterData:
      const originalFourQuarter = state.productSetup.halfHalfs
        ? state.productSetup.halfHalfs[0]
        : {};
      const defaultFourQuarterSize = originalFourQuarter.defaultSizes?.find(
        (size) => size.isActive
      );
      const fourQuarter = _generateCurrentFourQuarterData(
        originalFourQuarter,
        state.menuItems,
        originalFourQuarter.selectedSizes,
        defaultFourQuarterSize,
        state.currentHalfHalfData.variantSelected || ''
      );

      return {
        ...state,
        currentFourQuarterData: {
          ...state.currentFourQuarterData,
          activeCategoryId: payload.categoryId,
          fourQuarters: [
            { fourQuarterIndex: 1, ...fourQuarter },
            { fourQuarterIndex: 2, ...fourQuarter },
            { fourQuarterIndex: 3, ...fourQuarter },
            { fourQuarterIndex: 4, ...fourQuarter },
          ],
        },
      };
    case ActionTypes.UpdateCurrentFourQuarterItem:
      const lastItemQuarterIndex =
        state.currentOrder.menuItems.filter((mi) => mi.isQuarter).slice(-1)[0]
          ?.fourQuarterIndex || 0;
      return {
        ...state,
        currentFourQuarterData: {
          ...state.currentFourQuarterData,
          fourQuarters: state.currentFourQuarterData.fourQuarters.map((cat) => {
            if (cat.fourQuarterIndex === payload.menuItem.fourQuarterIndex) {
              return {
                ...cat,
                currentMenuItem: {
                  ...payload.menuItem,
                  fourQuarterIndex: lastItemQuarterIndex + 1,
                },
                isInEditMode: false,
              };
            }
            return {
              ...cat,
            };
          }),
        },
      };
    case ActionTypes.ToggleFourQuarterSelectedSize:
      const originalFourQuarter1 = state.productSetup.halfHalfs
        ? state.productSetup.halfHalfs[0]
        : {};
      const FourQuarter1 = _generateCurrentFourQuarterData(
        originalFourQuarter1,
        state.menuItems,
        originalFourQuarter1.selectedSizes,
        payload.size,
        state.currentHalfHalfData.variantSelected || ''
      );
      return {
        ...state,
        currentFourQuarterData: {
          ...state.currentFourQuarterData,
          fourQuarters: [
            { fourQuarterIndex: 1, ...FourQuarter1 },
            { fourQuarterIndex: 2, ...FourQuarter1 },
            { fourQuarterIndex: 3, ...FourQuarter1 },
            { fourQuarterIndex: 4, ...FourQuarter1 },
          ],
        },
      };
    case ActionTypes.ToggleFourQuarterEditMode:
      return {
        ...state,
        currentFourQuarterData: {
          ...state.currentFourQuarterData,
          fourQuarters: state.currentFourQuarterData.fourQuarters.map((cat) => {
            if (cat.fourQuarterIndex === payload.fourQuarterIndex) {
              return {
                ...cat,
                isInEditMode: true,
              };
            }
            return {
              ...cat,
              isInEditMode: false,
            };
          }),
        },
      };
    case ActionTypes.ResetCurrentFourQuarterData:
      return {
        ...state,
        currentFourQuarterData: {},
      };
    case ActionTypes.UpdateSpecialsHalfHalfData:
      const selectedCategory =
        state.currentSpecialsItem.selectedCategories[payload.categoryIndex];

      let originalHalfHalf2 = state.productSetup.halfHalfs.find((i) =>
        i.categories.some((c) =>
          selectedCategory.categories.some((sc) => sc._id === c._id)
        )
      );

      let defaultSize1 = {};

      if (
        selectedCategory.menuSizes.length > 0 &&
        originalHalfHalf2.selectedSizes.length > 0
      ) {
        defaultSize1 = selectedCategory.menuSizes.find((size) => {
          return originalHalfHalf2?.categories?.some(
            (cat) => cat?._id === size?.categoryId && size?.isActive
          );
        });
      } else if (
        selectedCategory.subModifiers.length > 0 &&
        originalHalfHalf2.selectedModifiers.length > 0
      ) {
        defaultSize1 = selectedCategory.subModifiers.find((m) =>
          originalHalfHalf2.selectedModifiers.some(
            (sm) =>
              sm._id === m._id &&
              originalHalfHalf2.defaultSizes[0].categoryId === m.modifierId
          )
        );
      }

      return {
        ...state,
        currentSpecialsItem: {
          ...state.currentSpecialsItem,
          selectedCategories: state.currentSpecialsItem.selectedCategories.map(
            (selCat) => {
              if (selCat.index === payload.categoryIndex) {
                const specialsHalfHalf = _generateCurrentHalfHalfData(
                  originalHalfHalf2,
                  selCat.menuItems,
                  selCat.menuSizes,
                  defaultSize1
                );

                return {
                  ...selCat,
                  currentHalfHalfData: {
                    halfHalfs: [
                      { halfIndex: 1, ...specialsHalfHalf },
                      { halfIndex: 2, ...specialsHalfHalf },
                    ],
                    variantSelected: '',
                  },
                };
              }
              return { ...selCat };
            }
          ),
        },
      };
    case ActionTypes.ResetSpecialsCurrentHalfHalfData:
      return {
        ...state,
        currentSpecialsItem: {
          ...state.currentSpecialsItem,
          selectedCategories:
            state.currentSpecialsItem?.selectedCategories?.map((selCat) => {
              if (selCat.index === payload.categoryIndex) {
                return {
                  ...selCat,
                  currentHalfHalfData: {},
                };
              }
              return { ...selCat };
            }),
        },
      };
    case ActionTypes.ToggleSpecialsHalfSelectedSize:
      const specialsOriginalHalfHalf = state.productSetup.halfHalfs
        ? state.productSetup.halfHalfs[0]
        : {};
      return {
        ...state,
        currentSpecialsItem: {
          ...state.currentSpecialsItem,
          selectedCategories: state.currentSpecialsItem.selectedCategories.map(
            (selCat) => {
              if (selCat.index === payload.categoryIndex) {
                const specialsHalfHalf = _generateCurrentHalfHalfData(
                  specialsOriginalHalfHalf,
                  selCat.menuItems,
                  selCat.menuSizes,
                  payload.size,
                  payload.variantSelected
                );
                return {
                  ...selCat,
                  currentHalfHalfData: {
                    ...selCat.currentHalfHalfData,
                    halfHalfs: [
                      { halfIndex: 1, ...specialsHalfHalf },
                      { halfIndex: 2, ...specialsHalfHalf },
                    ],
                    variantSelected: payload.variantSelected || '',
                  },
                };
              }
              return { ...selCat };
            }
          ),
        },
      };
    case ActionTypes.ToggleSpecialsHalfHalfEditMode:
      return {
        ...state,
        currentSpecialsItem: {
          ...state.currentSpecialsItem,
          selectedCategories: state.currentSpecialsItem.selectedCategories.map(
            (selCat) => {
              if (selCat.index === payload.categoryIndex) {
                return {
                  ...selCat,
                  currentHalfHalfData: {
                    halfHalfs: selCat.currentHalfHalfData.halfHalfs.map(
                      (cat) => {
                        if (cat.halfIndex === payload.halfIndex) {
                          return {
                            ...cat,
                            isInEditMode: true,
                          };
                        }
                        return {
                          ...cat,
                          isInEditMode: false,
                        };
                      }
                    ),
                  },
                };
              }
              return { ...selCat };
            }
          ),
        },
      };
    case ActionTypes.UpdateSpecialsCurrentHalfHalfItem:
      let lastHalfItemIndex = 0;
      state.currentOrder.menuItems.forEach((item) => {
        if (item?.isHalf) {
          if (lastHalfItemIndex < item?.halfIndex) {
            lastHalfItemIndex = item?.halfIndex;
          }
        }
      });
      state.currentSpecialsItem.selectedCategories?.forEach(
        (specialCategory) => {
          const { currentMenuItem: currentMenuItemFirstHalf = {} } =
            specialCategory?.currentHalfHalfData?.halfHalfs[0] || {};
          const { currentMenuItem: currentMenuItemSecondHalf = {} } =
            specialCategory?.currentHalfHalfData?.halfHalfs[1] || {};
          if (
            currentMenuItemFirstHalf?.halfIndex &&
            currentMenuItemSecondHalf?.halfIndex &&
            currentMenuItemFirstHalf?.halfIndex ===
              currentMenuItemSecondHalf?.halfIndex &&
            lastHalfItemIndex < currentMenuItemFirstHalf?.halfIndex
          ) {
            lastHalfItemIndex = currentMenuItemFirstHalf.halfIndex;
          }
        }
      );

      return {
        ...state,
        currentSpecialsItem: {
          ...state.currentSpecialsItem,
          selectedCategories: state.currentSpecialsItem.selectedCategories.map(
            (selCat) => {
              if (selCat.index === payload.categoryIndex) {
                return {
                  ...selCat,
                  currentHalfHalfData: {
                    ...selCat.currentHalfHalfData,
                    halfHalfs: selCat.currentHalfHalfData.halfHalfs.map(
                      (cat) => {
                        if (cat.halfIndex === payload.menuItem.halfIndex) {
                          return {
                            ...cat,
                            currentMenuItem: {
                              ...payload.menuItem,
                              halfIndex: cat.isInEditMode
                                ? cat.currentMenuItem.halfIndex
                                : lastHalfItemIndex + 1,
                            },
                            isInEditMode: false,
                          };
                        }
                        return {
                          ...cat,
                        };
                      }
                    ),
                  },
                };
              }
              return {
                ...selCat,
              };
            }
          ),
        },
      };

    case ActionTypes.UpdateSpecialsFourQuarterData:
      const originalFourQuarter2 = state.productSetup.halfHalfs
        ? state.productSetup.halfHalfs[0]
        : {};
      const defaultFourQuarterSize2 = originalFourQuarter2.defaultSizes?.find(
        (size) => size.isActive
      );

      return {
        ...state,
        currentSpecialsItem: {
          ...state.currentSpecialsItem,
          selectedCategories: state.currentSpecialsItem.selectedCategories.map(
            (selCat) => {
              if (selCat.index === payload.categoryIndex) {
                const specialsFourQuarter = _generateCurrentFourQuarterData(
                  originalFourQuarter2,
                  selCat.menuItems,
                  selCat.menuSizes,
                  defaultFourQuarterSize2
                );

                return {
                  ...selCat,
                  currentFourQuarterData: {
                    fourQuarters: [
                      { fourQuarterIndex: 1, ...specialsFourQuarter },
                      { fourQuarterIndex: 2, ...specialsFourQuarter },
                      { fourQuarterIndex: 3, ...specialsFourQuarter },
                      { fourQuarterIndex: 4, ...specialsFourQuarter },
                    ],
                  },
                };
              }
              return { ...selCat };
            }
          ),
        },
      };

    case ActionTypes.UpdateSpecialsCurrentFourQuarterItem:
      let lastQuarterItemIndex = 0;
      state.currentOrder.menuItems.forEach((item) => {
        if (item?.isQuarter) {
          if (lastQuarterItemIndex < item?.fourQuarterIndex) {
            lastQuarterItemIndex = item?.fourQuarterIndex;
          }
        }
      });

      state.currentSpecialsItem.selectedCategories?.forEach(
        (specialCategory) => {
          let currentFourQuarterIndex = 0;
          specialCategory?.currentFourQuarterData?.fourQuarters.forEach(
            (fourQuarter) => {
              if (fourQuarter?.currentMenuItem?.fourQuarterIndex) {
                currentFourQuarterIndex =
                  fourQuarter?.currentMenuItem?.fourQuarterIndex;
              } else {
                currentFourQuarterIndex = 0;
              }
            }
          );
          if (
            currentFourQuarterIndex &&
            lastQuarterItemIndex < currentFourQuarterIndex
          ) {
            lastQuarterItemIndex = currentFourQuarterIndex;
          }
        }
      );

      return {
        ...state,
        currentSpecialsItem: {
          ...state.currentSpecialsItem,
          selectedCategories: state.currentSpecialsItem.selectedCategories.map(
            (selCat) => {
              if (selCat.index === payload.categoryIndex) {
                return {
                  ...selCat,
                  currentFourQuarterData: {
                    fourQuarters:
                      selCat.currentFourQuarterData.fourQuarters.map((cat) => {
                        if (
                          cat.fourQuarterIndex ===
                          payload.menuItem.fourQuarterIndex
                        ) {
                          return {
                            ...cat,
                            currentMenuItem: {
                              ...payload.menuItem,
                              fourQuarterIndex: cat.isInEditMode
                                ? cat.currentMenuItem.fourQuarterIndex
                                : lastQuarterItemIndex + 1,
                            },
                            isInEditMode: false,
                          };
                        }
                        return {
                          ...cat,
                        };
                      }),
                  },
                };
              }
              return {
                ...selCat,
              };
            }
          ),
        },
      };
    case ActionTypes.ToggleSpecialsFourQuarterEditMode:
      return {
        ...state,
        currentSpecialsItem: {
          ...state.currentSpecialsItem,
          selectedCategories: state.currentSpecialsItem.selectedCategories.map(
            (selCat) => {
              if (selCat.index === payload.categoryIndex) {
                return {
                  ...selCat,
                  currentFourQuarterData: {
                    fourQuarters:
                      selCat.currentFourQuarterData.fourQuarters.map((cat) => {
                        if (cat.fourQuarterIndex === payload.fourQuarterIndex) {
                          return {
                            ...cat,
                            isInEditMode: true,
                          };
                        }
                        return {
                          ...cat,
                          isInEditMode: false,
                        };
                      }),
                  },
                };
              }
              return { ...selCat };
            }
          ),
        },
      };
    case ActionTypes.ResetSpecialsCurrentFourQuarterData:
      return {
        ...state,
        currentSpecialsItem: {
          ...state.currentSpecialsItem,
          selectedCategories:
            state.currentSpecialsItem?.selectedCategories?.map((selCat) => {
              if (selCat.index === payload.categoryIndex) {
                return {
                  ...selCat,
                  currentFourQuarterData: {},
                };
              }
              return { ...selCat };
            }),
        },
      };
    case ActionTypes.AuthenticateStaff:
      return {
        ...state,
        isStaffAuthorised: payload.flag,
        activeStaffMember: !payload.flag && null,
      };
    case ActionTypes.SET_ORDER_TYPE_CONFIG:
      return {
        ...state,
        orderTypeConfig: payload.orderTypeConfig,
      };
    case ActionTypes.SetActiveStaffMember:
      return {
        ...state,
        activeStaffMember: payload.staffDetails,
        isStaffAuthorised: true,
        currentOrder: {
          ...state.currentOrder,
          staffId: payload.staffDetails._id,
          staffName: `${payload.staffDetails?.firstName} ${payload.staffDetails?.lastName}`,
          staffMobile: payload.staffDetails?.mobile,
          staffEmail: payload.staffDetails?.email,
        },
      };
    case ActionTypes.SetGoToRouteAfterAuth:
      return {
        ...state,
        goToRouteAfterAuth: payload.route,
      };
    case ActionTypes.ScreenSizes:
      return {
        ...state,
        screenSizes: payload.sizes,
      };
    case ActionTypes.toggleOrderModal:
      return {
        ...state,
        orderSideModal: payload,
      };
    case ActionTypes.toggleSplitDiscountModal:
      return {
        ...state,
        splitBillDiscountModal: !state.splitBillDiscountModal,
        splitBillDiscountMode: payload.splitBillDiscountMode,
      };
    case ActionTypes.toggleSurchargeModal:
      return {
        ...state,
        surchargeModal: !state.surchargeModal,
      };
    // case ActionTypes.toggleItemViewModal:
    //   return {
    //     ...state,
    //     itemViewModalModal: !state.itemViewModalModal,
    //     itemViewModalMode: payload.itemViewModalMode,
    //   };
    case ActionTypes.GET_DISCOUNT_SUCCESS:
      return {
        ...state,
        discountList: payload.payload,
      };
    case ActionTypes.GET_DISCOUNT_FAILED:
      return {
        ...state,
        discountList: [],
      };

    // one use case of this is when order is in edit mode with discountId already present
    case ActionTypes.SET_CURRENT_ORDER_SELECTED_DISCOUNT:
      const { currentOrder, discountList } = state;
      return {
        ...state,
        currentOrder: {
          ...currentOrder,
          selectedDiscount:
            discountList?.find((dl) => dl._id === currentOrder.discountId) ||
            {},
        },
      };
    case ActionTypes.applyVoucher:
      return {
        ...state,
        appliedVoucher: payload.appliedVoucher,
      };
    case ActionTypes.GET_VOUCHER_SUCCESS:
      return {
        ...state,
        voucherList: payload.payload,
      };
    case ActionTypes.GET_WINDCAVE_USER_LIST: {
      return {
        ...state,
        windcaveUserList: payload?.windcaveUserList || [],
      };
    }
    case ActionTypes.GET_PINPADS: {
      const existingPinpads = state?.pinPads || [];
      const incomingPinpads = payload?.pinPads || [];

      // Match both array and store unique pinpads on the bases of id
      const uniquePinpads = [
        ...new Map(
          [...existingPinpads, ...incomingPinpads].map((item) => [
            item._id,
            item,
          ])
        ).values(),
      ];
      return {
        ...state,
        pinPads: uniquePinpads,
      };
    }
    case ActionTypes.GET_TABLES: {
      return {
        ...state,
        tables: payload.tables,
      };
    }
    case ActionTypes.GET_DRAWERS: {
      return {
        ...state,
        drawers: payload.drawers,
      };
    }
    case ActionTypes.SET_ORDER_PAYMENTS: {
      return {
        ...state,
        orderPayments: payload.orderPayments,
      };
    }

    case ActionTypes.SET_SPLIT_ORDER_SESSION_ID: {
      return {
        ...state,
        splitOrderSessionId: payload.splitOrderSessionId,
      };
    }
    case ActionTypes.SET_SPLIT_ORDER_ID: {
      return {
        ...state,
        splitOrderId: payload.splitOrderId,
      };
    }
    case ActionTypes.SET_SPLIT_ORDER_SERVICE_ID: {
      return {
        ...state,
        splitOrderServiceId: payload.splitOrderServiceId,
      };
    }
    case ActionTypes.LOAD_TERMINAL_SETTING: {
      return {
        ...state,
        terminalSettings: payload.terminalSettings,
      };
    }
    case ActionTypes.LOAD_CLOSE_DATES: {
      return {
        ...state,
        closeDates: payload.closeDates,
      };
    }
    case ActionTypes.LOAD_PUBLIC_HOLIDAYS: {
      return {
        ...state,
        publicHolidays: payload.publicHolidays,
      };
    }
    case ActionTypes.GET_ORDER_SETUP: {
      return {
        ...state,
        orderSetup: payload.orderSetup,
      };
    }
    case ActionTypes.SET_IS_ORDERS_LOADING: {
      return {
        ...state,
        isOrdersLoading: payload.isOrdersLoading,
      };
    }
    case ActionTypes.GET_PRODUCT_SETUP: {
      return {
        ...state,
        productSetup: payload.productSetup,
      };
    }
    case ActionTypes.LOAD_SHIFTS: {
      return {
        ...state,
        shifts: payload.shifts,
      };
    }
    case ActionTypes.LOAD_SUBURBS: {
      return {
        ...state,
        suburbs: payload.suburbs,
      };
    }
    case ActionTypes.LOAD_REFUND_REASONS: {
      return {
        ...state,
        refundReasons: payload.refundReasons,
      };
    }
    case ActionTypes.LOAD_SPECIALS: {
      return {
        ...state,
        specials: payload.specials,
      };
    }

    case ActionTypes.LOAD_SOLDOUTS_ITEM: {
      return {
        ...state,
        soldOutItems: payload.soldOutItems,
      };
    }

    case ActionTypes.LOAD_CATEGORIES: {
      return {
        ...state,
        categories: payload.categories,
      };
    }
    case ActionTypes.LOAD_MENUITEMS: {
      return {
        ...state,
        menuItems: payload.menuItems,
      };
    }
    case ActionTypes.LOAD_MENUITEM_SIZES: {
      return {
        ...state,
        menuItemSizes: payload.menuItemSizes,
      };
    }
    case ActionTypes.LOAD_MENUITEM_MODIFIERS: {
      return {
        ...state,
        menuItemModifiers: payload.menuItemModifiers,
      };
    }
    case ActionTypes.LOAD_MENUITEM_SUBMODIFIERS: {
      return {
        ...state,
        menuItemSubModifiers: payload.menuItemSubModifiers,
      };
    }
    case ActionTypes.cashAmountChange:
      return {
        ...state,
        cashAmountChange: payload.cashAmountChange,
      };
    case ActionTypes.UpdateCashModalTitle:
      return {
        ...state,
        title: payload.title,
      };
    case ActionTypes.cashPaid:
      return {
        ...state,
        cashPaid: payload.cashPaid,
      };
    case ActionTypes.SWITCH_ORDER_TYPE:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          ...payload.currentOrder,
          isOrderSwitched: true,
        },
      };
    case ActionTypes.UPDATE_STATE_ESCAPE_HATCH_DO_NOT_USE_THIS:
      return action?.payload.nextState;
    case ActionTypes.SET_ORDER_BACK_MODAL:
      return {
        ...state,
        orderBackModal: payload.setModal,
      };
    case ActionTypes.SET_DISABLE_SPECIAL_DISCOUNT_MODAL:
      return {
        ...state,
        disableSpecialDicountModal: payload.setModal,
      };
    case ActionTypes.SET_CURRENT_MENU_ITEM_NOTES:
      return {
        ...state,
        currentMenuItem: {
          ...state.currentMenuItem,
          itemNotes: payload.itemNotes,
        },
      };
    case ActionTypes.SET_CURRENT_HALF_HALF_NOTES:
      return {
        ...state,
        currentHalfHalfData: {
          ...state.currentHalfHalfData,
          itemNotes: payload.itemNotes,
        },
      };
    case ActionTypes.OPEN_ITEM_NOTES_MODAL:
      return {
        ...state,
        openItemNotesModal: payload.openItemNotesModal,
      };
    case ActionTypes.REFRESH_TO_UPDATE_POS_VERSION:
      return {
        ...state,
        refreshToUpdatePosVersion: payload.shouldRefresh,
      };
    case ActionTypes.SET_PAYMENT_TYPES:
      return {
        ...state,
        paymentTypes: payload.paymentTypes,
      };
    case ActionTypes.API_LOADING:
      return {
        ...state,
        apiLoading: payload.apiLoading,
      };
    case ActionTypes.IS_CANCEL_TRANSACTION_API_LOADING:
      return {
        ...state,
        isCancelTransactionApiLoading: payload.isCancelTransactionApiLoading,
      };
    case ActionTypes.STORE_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: payload.accessToken,
      };
    case ActionTypes.CLEAR_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: '',
      };
    case ActionTypes.SET_ACTIVE_SEAT_ID:
      return {
        ...state,
        activeSeatId: payload.activeSeatId,
      };
    default:
      return state;
  }
};

export default dashboardReducer;

export function toggleHalfHalfSelectedVariant(state, action) {
  return {
    ...state,
    currentHalfHalfData: {
      ...state.currentHalfHalfData,
      variantSelected: action?.payload?.toSetVariant,
    },
  };
}
// for reference: payload needs `menuItem` property
export function updateCurrentHalfAndHalfItem(state, payload) {
  const lastItemHalfIndex =
    state.currentOrder.menuItems.filter((mi) => mi.isHalf).slice(-1)[0]
      ?.halfIndex || 0;

  return {
    ...state,
    currentHalfHalfData: {
      ...state.currentHalfHalfData,
      halfHalfs: state.currentHalfHalfData.halfHalfs.map((cat) => {
        if (cat.halfIndex === payload.menuItem.halfIndex) {
          return {
            ...cat,
            currentMenuItem: {
              ...payload.menuItem,
              halfIndex: state.currentHalfHalfData.isInModifyMode
                ? state.currentHalfHalfData.halfIndexForModify
                : lastItemHalfIndex + 1,
            },
            isInEditMode: false,
          };
        }
        return {
          ...cat,
        };
      }),
    },
  };
}

export function toggleHalfHalfSelectedSizeStateChanger(state, action) {
  return {
    ...state,
    currentHalfHalfData: {
      ...state.currentHalfHalfData,
      halfHalfs: [
        { halfIndex: 1, ...action.payload.firstHalfData },
        { halfIndex: 2, ...action.payload.secondHalfData },
      ],
    },
  };
}
